<template>
  <teleport to="#toolbar">
    <div class="w-full px-6 py-6">
      <div class="flex gap-5 items-center">
        <div class="inline-flex text-xl font-bold leading-5 text-blue-900">
          Projects
        </div>
        <CreateProject v-if="isAdmin" />
      </div>
    </div>
  </teleport>
  <div class="w-full h-full">
    <div v-if="projects.length > 0" class="p-6">
      <div class="flex flex-col gap-6">
        <ProjectTable
          v-for="project in projects.sort(
            (y, x) => Number(x.isFavorite) - Number(y.isFavorite),
          )"
          :key="project.id"
          :project="project"
          :users="users.users.value"
        />
      </div>
    </div>
    <div
      v-else-if="!loading.isLoading.value"
      class="w-full flex items-center justify-center pt-28"
    >
      <div
        class="border-2 border-dashed bg-white border-slate-300 rounded-lg text-center px-80 py-32 space-y-2"
      >
        <CreateProjectIcon class="w-10 h-10 text-primary mx-auto" />
        <p class="pb-3">
          There are no projects yet, <br />start by creating one
        </p>
        <CreateProject v-if="isAdmin" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useProjects from '@app/views/Projects/use-projects'
import useLoading from '@app/composables/use-loading'
import ProjectTable from './Project/ProjectTable.vue'
import CreateProjectIcon from '@app/components/Icons/CreateProjectIcon.vue'
import useUsers from '@app/composables/use-users'
import CreateProject from './CreateProject.vue'
import { computed } from 'vue'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'

const loading = useLoading()
const { projects, refresh } = useProjects()

const users = useUsers()
users.refresh()
const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => user.value?.role === Role.ADMIN)
loading.start()
refresh().then(() => loading.stop())
</script>
