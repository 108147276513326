<template>
  <div class="flex items-center gap-2">
    <div class="mr-36 flex items-center">
      <a
        v-if="!isExtensionInstalled"
        id="install-extension-link"
        href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
        target="_blank"
        rel="noopener"
      >
        <img
          class="h-12"
          src="/download-extension.png"
          alt="Install Evidence Extension"
        />
      </a>
      <div class="h-8 w-px bg-slate-300" />
    </div>
    <FloatingMenu
      ref="floatingMenu"
      placement="bottom-start"
      :arrow="true"
      :offset="[0, 10]"
    >
      <template #activator>
        <button
          class="font-bold border p-5 text-white rounded-full bg-primary w-5 h-5 text-sm uppercase flex items-center justify-center dark:bg-[#103047] dark:text-white"
          @click="openMenu"
        >
          {{ user?.email?.charAt(0) ?? '' }}
        </button>
      </template>
      <template #content>
        <div
          class="w-[128px] text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
        >
          <button
            class="py-2 px-4 uppercase tracking-wide hover:bg-black/5 dark:hover:bg-[#344F62] w-full flex"
            @click="logout"
          >
            logout
          </button>
        </div>
      </template>
    </FloatingMenu>
  </div>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import useWebExtension from '@app/composables/useWebExtension'

const { user, logout: authLogout } = injectStrict(AuthKey)

const { isExtensionInstalled, logoutExtension } = useWebExtension()

const floatingMenu = ref()

async function openMenu() {
  floatingMenu?.value?.show()
}
async function logout() {
  authLogout()
  logoutExtension()
}
</script>
