import { computed, ref } from 'vue'
import { ExecuteStep } from '../../review.types'

const activeExecuteStep = ref(ExecuteStep.screening)

export default () => {
  const isScreeningStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.screening,
  )

  const isStudyDesignStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.studyDesign,
  )

  const isAppraisalStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.appraisal,
  )

  const isSynthesisStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.synthesis,
  )

  function setActiveExecuteStep(step: ExecuteStep) {
    activeExecuteStep.value = step
  }

  return {
    isStudyDesignStageActive,
    isScreeningStageActive,
    isAppraisalStageActive,
    isSynthesisStageActive,
    activeExecuteStep,
    setActiveExecuteStep,
  }
}
