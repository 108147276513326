<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    class="fill-current"
  >
    <path
      d="M10.6244 6.22387L13.2001 3.64814V5.79961C13.2001 6.13098 13.4687 6.39961 13.8001 6.39961C14.1315 6.39961 14.4001 6.13098 14.4001 5.79961V2.19961C14.4001 1.86824 14.1315 1.59961 13.8001 1.59961H10.2001C9.86873 1.59961 9.6001 1.86824 9.6001 2.19961C9.6001 2.53098 9.86873 2.79961 10.2001 2.79961H12.3516L9.77583 5.37535C9.54152 5.60966 9.54152 5.98956 9.77583 6.22387C10.0101 6.45819 10.39 6.45819 10.6244 6.22387Z"
    />
    <path
      d="M1.6001 13.7996V10.1996C1.6001 9.86824 1.86873 9.59961 2.2001 9.59961C2.53147 9.59961 2.8001 9.86824 2.8001 10.1996V12.3511L5.37583 9.77535C5.61015 9.54103 5.99005 9.54103 6.22436 9.77535C6.45868 10.0097 6.45868 10.3896 6.22436 10.6239L3.64863 13.1996H5.8001C6.13147 13.1996 6.4001 13.4682 6.4001 13.7996C6.4001 14.131 6.13147 14.3996 5.8001 14.3996H2.2001C2.04654 14.3996 1.89299 14.341 1.77583 14.2239C1.71831 14.1663 1.6749 14.1 1.64562 14.0293C1.61629 13.9585 1.6001 13.881 1.6001 13.7996Z"
    />
    <path
      d="M9.77583 10.6239L12.3516 13.1996H10.2001C9.86873 13.1996 9.6001 13.4682 9.6001 13.7996C9.6001 14.131 9.86873 14.3996 10.2001 14.3996H13.8001C13.9537 14.3996 14.1072 14.341 14.2244 14.2239C14.2819 14.1663 14.3253 14.1 14.3546 14.0293C14.3839 13.9585 14.4001 13.881 14.4001 13.7996V10.1996C14.4001 9.86824 14.1315 9.59961 13.8001 9.59961C13.4687 9.59961 13.2001 9.86824 13.2001 10.1996V12.3511L10.6244 9.77535C10.39 9.54103 10.0101 9.54103 9.77583 9.77535C9.54152 10.0097 9.54152 10.3896 9.77583 10.6239Z"
    />
    <path
      d="M2.8001 3.64814L5.37583 6.22387C5.61015 6.45819 5.99005 6.45819 6.22436 6.22387C6.45868 5.98956 6.45868 5.60966 6.22436 5.37535L3.64863 2.79961H5.8001C6.13147 2.79961 6.4001 2.53098 6.4001 2.19961C6.4001 1.86824 6.13147 1.59961 5.8001 1.59961H2.2001C1.86873 1.59961 1.6001 1.86824 1.6001 2.19961V5.79961C1.6001 6.13098 1.86873 6.39961 2.2001 6.39961C2.53147 6.39961 2.8001 6.13098 2.8001 5.79961V3.64814Z"
    />
  </svg>
</template>
