<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    class="fill-current"
  >
    <path d="M9.99992 12.083L14.1666 7.91634L5.83325 7.91634L9.99992 12.083Z" />
  </svg>
</template>
