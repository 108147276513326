<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    class="stroke-current !mt-0"
  >
    <path
      d="M12.3335 49.3337L12.3335 52.417C12.3335 57.5256 16.4749 61.667 21.5835 61.667L52.4168 61.667C57.5255 61.667 61.6668 57.5256 61.6668 52.417L61.6668 49.3337M49.3335 37.0003L37.0002 49.3337M37.0002 49.3337L24.6668 37.0003M37.0002 49.3337L37.0002 12.3337"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
