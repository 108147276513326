<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    class="fill-current"
  >
    <path
      d="M2.7002 10.7998V13.4998C2.7002 14.991 5.5208 16.1998 9.0002 16.1998C12.4796 16.1998 15.3002 14.991 15.3002 13.4998V10.7998C15.3002 12.291 12.4796 13.4998 9.0002 13.4998C5.5208 13.4998 2.7002 12.291 2.7002 10.7998Z"
    />
    <path
      d="M2.7002 6.2998V8.9998C2.7002 10.491 5.5208 11.6998 9.0002 11.6998C12.4796 11.6998 15.3002 10.491 15.3002 8.9998V6.2998C15.3002 7.79097 12.4796 8.9998 9.0002 8.9998C5.5208 8.9998 2.7002 7.79097 2.7002 6.2998Z"
    />
    <path
      d="M15.3002 4.4998C15.3002 5.99097 12.4796 7.1998 9.0002 7.1998C5.5208 7.1998 2.7002 5.99097 2.7002 4.4998C2.7002 3.00864 5.5208 1.7998 9.0002 1.7998C12.4796 1.7998 15.3002 3.00864 15.3002 4.4998Z"
    />
  </svg>
</template>
