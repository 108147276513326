import { parseStringPromise } from 'xml2js'
import axios from 'axios'
import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'

interface ESearchResult {
  esearchresult: {
    idlist: string[]
    count: number
    querytranslation: string
    translationset: { from: string; to: string }[]
  }
}
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export class PubmedApiClient {
  async searchArticles(
    query: string,
  ): Promise<{ articles: ArticleMetadata[]; queryTranslation: string }> {
    await delay(1000) // Add delay to avoid overloading the API
    try {
      const response = await axios.get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&term=${encodeURIComponent(query)}&retmode=json&retstart=0&retmax=9999`,
      )
      const articles = await this.fetchStudiesDetails(
        response.data.esearchresult.idlist,
      )
      return {
        articles: articles.map((article: any) => new ArticleMetadata(article)),
        queryTranslation: response.data.esearchresult.querytranslation,
      }
    } catch (error) {
      console.error('Error fetching articles from PubMed:', error)
      throw error
    }
  }

  async findStudies(pubmedUrl: string): Promise<{
    metadata: ArticleMetadata[]
    queryTranslation: string
    term: string
  }> {
    const { term, filters } = this.parsePubMedURL(pubmedUrl)
    const query = this.transformPubmedFilters(term, filters)

    const encodedTerm = encodeURIComponent(query)
    const pubmedSearch = await this.fetchPubmedSearch(encodedTerm)

    if (pubmedSearch.articlesIds.length <= 0) {
      return {
        metadata: [],
        queryTranslation: pubmedSearch.queryTranslation,
        term,
      }
    }

    const metadata = await this.fetchStudiesDetails(pubmedSearch.articlesIds)

    return { metadata, queryTranslation: pubmedSearch.queryTranslation, term }
  }

  async getPmidFromDoi(doi: string): Promise<string> {
    const { data } = await axios.get(this.getIdConversionUrl(doi))
    const pmid = await this.extractPmid(data)
    return pmid
  }

  async fetchStudiesDetails(idList: string[]): Promise<
    {
      title?: string
      abstract?: string
      authors?: string[]
      pmid?: string
      doi?: string
      publishYear?: string
      url?: string
      journal?: string
      volumeNumber?: string
      issueNumber?: string
      pagesNumber?: string
    }[]
  > {
    const batchSize = 50
    const batches = []
    for (let i = 0; i < idList.length; i += batchSize) {
      batches.push(idList.slice(i, i + batchSize))
    }
    const pubmedArticleSets = []
    for (const batch of batches) {
      await delay(1000) // Add delay to avoid overloading the API
      const postData = new URLSearchParams()
      postData.append('db', 'pubmed')
      postData.append('id', batch.join(','))
      postData.append('retmode', 'xml')

      const fetchDetails = await fetch(
        'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi',
        {
          method: 'POST',
          body: postData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )

      const detailsXml = await fetchDetails.text()
      const parsedXml = await parseStringPromise(detailsXml)
      pubmedArticleSets.push(parsedXml.PubmedArticleSet)
    }

    const results = pubmedArticleSets.reduce(
      (t, s) => {
        t.pubmedArticles = [...t.pubmedArticles, ...(s?.PubmedArticle ?? [])]
        t.pubmedBookArticles = [
          ...t.pubmedBookArticles,
          ...(s?.PubmedBookArticle ?? []),
        ]
        return t
      },
      {
        pubmedArticles: [],
        pubmedBookArticles: [],
      },
    )

    const pubmedArticles = await this.parsePubmedArticles(
      results.pubmedArticles,
    )
    const pubmedBookArticles = await this.parsePubmedBookArticles(
      results.pubmedBookArticles,
    )
    return [...pubmedArticles, ...pubmedBookArticles]
  }

  private getIdConversionUrl(doi: string): string {
    return `https://www.ncbi.nlm.nih.gov/pmc/utils/idconv/v1.0/?tool=my_tool&email=my_email@example.com&ids=${doi}`
  }

  private async extractPmid(data: string): Promise<string> {
    const result = await parseStringPromise(data)
    return result.pmcids.record[0].$.pmid
  }

  private async fetchPubmedSearch(
    encodedTerm: string,
  ): Promise<{ articlesIds: string[]; queryTranslation: string }> {
    await delay(1000) // Add delay to avoid overloading the API
    let requestUrl = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&term=${encodedTerm}&retmode=json`
    requestUrl += `&retstart=0`
    requestUrl += `&retmax=10000`

    const res = await fetch(requestUrl)
    const data: ESearchResult = await res.json()

    return {
      articlesIds: data.esearchresult.idlist ?? [],
      queryTranslation: data.esearchresult.querytranslation,
    }
  }

  private async parsePubmedArticles(pubmedEntries: any[]) {
    return pubmedEntries.map((article) => {
      const medlineCitation = article.MedlineCitation[0]
      const articleData = medlineCitation.Article[0]

      const title = this.buildTitle(articleData.ArticleTitle[0])
      const abstract = this.buildAbstract(articleData)
      const authors = this.buildAuthors(articleData)
      const pmid = this.buildPmid(medlineCitation)
      const doi = this.buildDoi(article.PubmedData)
      const publishYear = this.buildPublishYear(articleData)
      const journal = this.buildJournal(articleData)
      const volumeNumber = this.buildVolumeNumber(articleData)
      const issueNumber = this.buildIssueNumber(articleData)
      const pagesNumber = this.buildPageNumber(articleData)
      const url = this.buildUrl(pmid)

      return {
        title,
        abstract,
        authors,
        pmid,
        doi,
        publishYear,
        url,
        journal,
        volumeNumber,
        issueNumber,
        pagesNumber,
      }
    })
  }

  private buildUrl(pmid: any) {
    return pmid ? `https://pubmed.ncbi.nlm.nih.gov/${pmid}/` : ''
  }

  private buildPageNumber(articleData: any) {
    return articleData.Pagination?.[0]?.MedlinePgn?.[0] ?? ''
  }

  private buildIssueNumber(articleData: any) {
    return articleData.Journal?.[0]?.JournalIssue?.[0]?.Issue?.[0] ?? ''
  }

  private buildVolumeNumber(articleData: any) {
    return articleData.Journal?.[0]?.JournalIssue?.[0]?.Volume?.[0] ?? ''
  }

  private buildJournal(articleData: any) {
    return articleData.Journal?.[0]?.Title?.[0] ?? ''
  }

  private buildPmid(medlineCitation: any) {
    return medlineCitation.PMID?.[0]?._
  }

  private buildAbstract(articleData: any): string {
    const abstractText = articleData.Abstract?.[0]?.AbstractText
    if (Array.isArray(abstractText)) {
      return abstractText
        .map((part) => (typeof part === 'object' ? part._ : part))
        .join(' ')
    } else if (typeof abstractText === 'string') {
      return abstractText
    }
    return 'N/A'
  }

  private buildAuthors(articleData: any): string[] {
    return (
      articleData.AuthorList?.[0]?.Author?.filter(
        (author: any) => author.LastName?.[0] || author.ForeName?.[0],
      ).map((author: any) => {
        return `${author.LastName?.[0] ?? ''}, ${
          author.ForeName?.[0] ?? ''
        }`.trim()
      }) ?? []
    )
  }

  private buildDoi(pubmedData: any[]): string {
    const articleIdList = pubmedData?.[0]?.ArticleIdList?.[0]?.ArticleId || []
    const doi = articleIdList.find((id: any) => id.$.IdType === 'doi')
    return doi?._ ?? ''
  }

  private buildTitle(articleTitle: any): string {
    if (typeof articleTitle === 'string') {
      return articleTitle
    } else if (articleTitle?._) {
      let titleText = articleTitle._
      if (articleTitle.i) {
        const inlineElements = Array.isArray(articleTitle.i)
          ? articleTitle.i.join(' ')
          : articleTitle.i
        titleText += ` (${inlineElements})`
      }
      return titleText
    }
    return 'N/A'
  }

  private buildPublishYear(articleData: any): string {
    return (
      articleData.Journal?.[0]?.JournalIssue?.[0]?.PubDate?.[0]?.Year?.[0] ?? ''
    )
  }

  private async parsePubmedBookArticles(
    pubmedBookEntries: any[],
  ): Promise<any[]> {
    return pubmedBookEntries.map((entry) => {
      const bookDocument = entry.BookDocument?.[0]

      const title = bookDocument?.ArticleTitle?.[0]?._ ?? 'N/A'
      const abstract = this.extractBookAbstract(bookDocument?.Abstract)
      const authors = this.extractBookAuthors(
        bookDocument?.AuthorList?.[0]?.Author,
      )
      const pmid = bookDocument?.PMID?.[0]?._ ?? ''
      const doi = this.extractBookDoi(
        bookDocument?.ArticleIdList?.[0]?.ArticleId,
      )
      const publishYear = bookDocument?.Book?.[0]?.PubDate?.[0]?.Year?.[0] ?? ''

      const url = pmid ? `https://pubmed.ncbi.nlm.nih.gov/${pmid}/` : ''

      const journal = ''
      const volumeNumber = ''
      const issueNumber = ''
      const pagesNumber = ''

      return {
        title,
        abstract,
        authors,
        pmid,
        doi,
        publishYear,
        url,
        journal,
        volumeNumber,
        issueNumber,
        pagesNumber,
      }
    })
  }

  private extractBookAbstract(abstractData: any): string {
    if (!abstractData?.[0]?.AbstractText) {
      return 'N/A'
    }

    const abstractTextArray = abstractData[0].AbstractText
    if (Array.isArray(abstractTextArray)) {
      return abstractTextArray
        .map((text) => (typeof text === 'string' ? text : text._))
        .join(' ')
    }

    return typeof abstractTextArray === 'string'
      ? abstractTextArray
      : (abstractTextArray._ ?? 'N/A')
  }

  private extractBookAuthors(authors: any[]): string[] {
    return (
      authors?.map((author) =>
        `${author.LastName?.[0]}, ${author.ForeName?.[0]}`.trim(),
      ) ?? []
    )
  }

  private extractBookDoi(articleIds: any[]): string {
    const doi = articleIds?.find((id) => id.$?.IdType === 'doi')
    return doi?._ ?? ''
  }

  private parsePubMedURL(url: string): { term: string; filters: string[] } {
    const urlObj = new URL(url)
    const queryParams = urlObj.searchParams

    const term = queryParams.get('term') ?? ''
    const filters = queryParams.getAll('filter')

    return { term, filters }
  }

  private transformPubmedFilters(term: string, filters: string[]): string {
    const filterGroups = filters.reduce(
      (acc, filter) => {
        const parts = filter.split('.')
        if (parts.length === 2) {
          const [prefix] = parts
          let [, value] = parts

          if (prefix === 'years' || prefix === 'dates') {
            value = value.replace(/\//g, '/')
            acc['pdat'] = [`${value.split('-').join(':')}[pdat]`]
          } else {
            acc[prefix] = acc[prefix] || []
            acc[prefix].push(`${value}[Filter]`)
          }
        }
        return acc
      },
      {} as Record<string, string[]>,
    )

    const combinedFilters = Object.entries(filterGroups).map(([, group]) => {
      return group.length > 1 ? `(${group.join(' OR ')})` : group[0]
    })

    return combinedFilters.length > 0
      ? `${term} AND ${combinedFilters.join(' AND ')}`
      : term
  }
}
