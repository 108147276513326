export enum BuiltInImportSourceId {
  PUBMED = 'pubmed',
  GOOGLE_SCHOLAR = 'google-scholar',
  DIMENSIONS_AI = 'dimensions',
  COCHRANE = 'cochrane-database-of-systematic-reviews',
  HAND_SEARCH = 'hand-search',
  CITATION_SEARCH = 'citation-search',
  MAUDE = 'maude',
  OPEN_ALEX = 'open-alex',
  MDCG = 'mdcg-(medical-devices-cordinating-group)',
  OPEN_FDA = 'open-fda',
  ICTRP = 'international-clinical-trials-registry-platform',
  PMC = 'pmc',
  CENELEC = 'cenelec',
  EUROPE_PMC = 'europe-pmc',
  PROSPERO = 'prospero',
  LIVIVO = 'livivo',
  BASE = 'base',
  EMBASE = 'embase',
  WILEY_ONLINE_LIBRARY = 'wiley-online-library',
  MAG_ONLINE = 'mag-online',
  SCIENCE_DIRECT = 'science-direct',
  FIELD_SAFETY_NOTICES = 'safety-information',
  DAEN = 'daen',
  MDID = 'mdid',
}
