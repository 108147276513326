<template>
  <div class="p-6 max-w-[380px]">
    <TextInput
      placeholder="Email"
      :model-value="user?.email"
      label="Email"
      :disabled="true"
      class="py-2"
    />
    <TextInput
      v-model="userFullName.firstName"
      placeholder="First name"
      label="First name"
      class="py-2"
    />
    <TextInput
      v-model="userFullName.lastName"
      placeholder="Last name"
      label="Last name"
      class="py-2"
    />

    <button
      class="px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary w-20 h-10 bg-primary rounded-md"
      @click="updateCurrentUserFullName(userFullName)"
    >
      Save
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'

import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey, UsersServiceKey } from '@app/injectionKeys'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import useLoading from '@app/composables/use-loading'
import useUsers from '@app/composables/use-users'
import { UsersService } from '@core/application/users.service'
const { user, setUser } = injectStrict(AuthKey)
const userFullName = ref({
  firstName: user.value?.firstName ?? '',
  lastName: user.value?.lastName ?? '',
})
const snackbar = useSnackbar()
const users = useUsers()
const loading = useLoading()
const usersService: UsersService = injectStrict(UsersServiceKey)

async function updateCurrentUserFullName(fullName: {
  firstName: string
  lastName: string
}) {
  try {
    loading.start()
    await users.updateCurrentUserFullName(fullName)
    snackbar.show(SnackbarState.SUCCESS, 'Profile updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
  const user = await usersService.findCurrentUser().catch(() => null)
  if (user) setUser(user)
}
</script>
