export enum PlanStep {
  evaluators = 1,
  objective = 2,
  strategy = 3,
  appraisal = 4,
  importSources = 5,
  lock = 6,
}

export enum ReviewStep {
  plan = 1,
  execute = 2,
  export = 3,
  reviewLock = 4,
}

export enum ExecuteStep {
  screening = 1,
  studyDesign = 2,
  appraisal = 3,
  synthesis = 4,
  reviewLock = 5,
}
