import { generateColorFromString } from '@app/utils/generateColorFromString'
import { UsersService } from '@core/application/users.service'
import { User } from '@core/domain/models/user.model'
import { readonly, ref } from 'vue'

const user = ref<User>()
const userColor = ref<string>('#000000')

export default function useAuth(usersService: UsersService) {
  const setUser = (value: User) => {
    user.value = value
    userColor.value = generateColorFromString(
      value.id || value.firstName || value.lastName,
    )
  }

  const logout = async () => {
    user.value = undefined
    await usersService.logout()
    window.location.href = '/login'
  }

  async function getRecoveryLink() {
    const link = await usersService.getRecoveryLink()
    return link
  }

  return {
    setUser,
    user: readonly(user),
    logout,
    color: readonly(userColor),
    getRecoveryLink,
  }
}
