import { Review } from '@core/domain/models/review.model'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { generateCitation } from './generate-citations'

export type ReviewStudyReference = {
  studyId: number
  inText: string
  fullText: string
}

export async function getReviewItemsReferences(
  review: Review,
): Promise<Record<ReviewItemType, ReviewStudyReference[]>> {
  const scholarlyArticles = review.studies.filter(
    (s) => s.type === ReviewItemType.Article,
  )

  const citations = await generateCitation(
    scholarlyArticles,
    review.project!.cslStyle!,
  )

  function mapStudies(
    type: ReviewItemType,
    getInText: (study: any) => string,
    getFullText: (study: any) => string,
  ): ReviewStudyReference[] {
    return review.studies
      .filter((s) => s.type === type)
      .map((s) => ({
        studyId: s.id,
        inText: getInText(s),
        fullText: getFullText(s),
      }))
  }

  const studiesByType: Record<ReviewItemType, ReviewStudyReference[]> = {
    [ReviewItemType.FieldSafetyNotice]: mapStudies(
      ReviewItemType.FieldSafetyNotice,

      (r) => (r.metadata.fsnId ? 'Fsn-id - ' + r.metadata.fsnId : 'ref'),
      (r) => r.metadata.title ?? '-',
    ),

    [ReviewItemType.Article]: mapStudies(
      ReviewItemType.Article,
      (a) => citations.findByStudyId(a.id)?.inText ?? 'ref',
      (a) => citations.findByStudyId(a.id)?.fullText ?? '-',
    ),

    [ReviewItemType.Incident]: mapStudies(
      ReviewItemType.Incident,
      (i) =>
        i.metadata.reportNumber ? 'Maude - ' + i.metadata.reportNumber : 'ref',
      (i) => i.metadata.title ?? '-',
    ),

    [ReviewItemType.Registration]: mapStudies(
      ReviewItemType.Registration,
      (r) => r.metadata.registrationId ?? 'ref',
      (r) => r.metadata.title ?? '-',
    ),

    [ReviewItemType.Guidance]: (() => {
      const frequencyMap: Record<string, number> = {}
      const studies = review.studies.filter(
        (s) => s.type === ReviewItemType.Guidance,
      )

      studies.forEach((g) => {
        const inText = g.metadata.section
          ? `MDCG - ${g.metadata.section} ${g.metadata.publishYear}`
          : 'ref'
        frequencyMap[inText] = (frequencyMap[inText] || 0) + 1
      })

      Object.keys(frequencyMap).forEach((key) => {
        if (frequencyMap[key] === 1) {
          frequencyMap[key] = -1
        }
      })

      return studies.map((g) => {
        const baseInText = g.metadata.section
          ? `MDCG - ${g.metadata.section} ${g.metadata.publishYear}`
          : 'ref'

        let uniqueInText = baseInText
        if (frequencyMap[baseInText] > 0) {
          const occurrenceIndex = frequencyMap[baseInText] - 1
          uniqueInText = `${baseInText}${String.fromCharCode(97 + occurrenceIndex)}`
          frequencyMap[baseInText]--
        }

        return {
          studyId: g.id,
          inText: uniqueInText,
          fullText: g.metadata.title ?? '-',
        }
      })
    })(),

    [ReviewItemType.Standard]: [],
    [ReviewItemType.default]: [],
  }

  return studiesByType
}
