import { Review, StatsEntry } from '@core/domain/models/review.model'
import { Workbook, Worksheet } from 'exceljs'
import { generateReviewScreeningResults } from './generate-review-screening-result'
import { generateReviewSearchsAndLimtsResults } from './generate-search-terms-limits-result'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import {
  ReviewStudyReference,
  getReviewItemsReferences,
} from './getReviewItemsReferences'

export async function generateScreeningResultsCsv(
  review: Review,
): Promise<Blob> {
  const sourcesStats = review.stats.sources
  const filesStats = review.stats.searches
  const overallStats = review.stats.overall

  const overallRows: string[][] = []
  const bySourceRows: string[][] = []
  const byFileRows: string[][] = []

  overallRows.push(...generateCsvSection(review, overallStats, 'overall'))
  Object.keys(sourcesStats).forEach((k) => {
    const isSourceEmpty = Object.keys(sourcesStats[k]).every(() => {
      const entry = sourcesStats[k]
      return (
        entry.included === 0 &&
        entry.total === 0 &&
        Object.keys(entry.titleAndAbstractExcludedScreening).every(
          (sc1) => entry.titleAndAbstractExcludedScreening[sc1] === 0,
        ) &&
        Object.keys(entry.fullTextExcludedScreening).every(
          (sc2) => entry.fullTextExcludedScreening[sc2] === 0,
        )
      )
    })
    if (isSourceEmpty) return

    const entry = sourcesStats[k]
    bySourceRows.push(
      ...generateCsvSection(review, entry, k.replaceAll('_', ' ')),
    )
  })

  Object.keys(filesStats).forEach((k) => {
    const entry = filesStats[k]
    byFileRows.push(...generateCsvSection(review, entry, k))
  })

  const workbook = new Workbook()
  const screeningSummaryOverallSheet = workbook.addWorksheet(
    'Screening Summary overall',
  )
  const screeningSummaryBySourceSheet = workbook.addWorksheet(
    'Screening Summary by source',
  )
  const screeningSummaryBySearchSheet = workbook.addWorksheet(
    'Screening Summary by search',
  )
  const result = workbook.addWorksheet('Screening results')
  const searches = workbook.addWorksheet('searches results')

  screeningSummaryOverallSheet.addRows(overallRows)
  screeningSummaryBySourceSheet.addRows(bySourceRows)
  screeningSummaryBySearchSheet.addRows(byFileRows)
  const studiesByType = await getReviewItemsReferences(review)

  generateScreeningResultsWorksheet(result, review, studiesByType)
  generateSearchesWorksheet(searches, review)

  const buffer = await workbook.xlsx.writeBuffer()
  return new Blob([buffer])
}

function generateCsvSection(review: Review, entry: StatsEntry, key: string) {
  const rows = []
  rows.push([key, '', `${entry.total}`, '', `TOTAL (${entry.total})`])
  rows.push([
    '',
    'DUPLICATES',
    entry.duplicate.toString(),
    '',
    `DUPLICATES (${entry.duplicate.toString()})`,
  ])

  if ((review.plan?.screeningPlan.titleAndAbstractCriteria.length ?? 0) > 0) {
    rows.push([
      '',
      'EXCLUDED ON TITLE-AND-ABSTRACT:',
      entry.titleAndAbstractExcludedScreeningTotal.toString(),
      '',
      `EXCLUDED ON TITLE-AND-ABSTRACT (${entry.titleAndAbstractExcludedScreeningTotal.toString()})`,
    ])
    Object.keys(entry.titleAndAbstractExcludedScreening).forEach((sc1) =>
      rows.push([
        '',
        sc1,
        entry.titleAndAbstractExcludedScreening[sc1].toString(),
        '',
        `${sc1} (${entry.titleAndAbstractExcludedScreening[sc1].toString()})`,
      ]),
    )
    rows.push([
      '',
      'EXCLUDED ON FULL-TEXT:',
      entry.fullTextExcludedScreeningTotal.toString(),
      '',
      `EXCLUDED ON FULL-TEXT (${entry.fullTextExcludedScreeningTotal.toString()})`,
    ])
    Object.keys(entry.fullTextExcludedScreening).forEach((sc2) =>
      rows.push([
        '',
        sc2,
        entry.fullTextExcludedScreening[sc2].toString(),
        '',
        `${sc2} (${entry.fullTextExcludedScreening[sc2].toString()})`,
      ]),
    )
  } else {
    rows.push([
      '',
      'EXCLUDED',
      entry.fullTextExcludedScreeningTotal.toString(),
      '',
      `EXCLUDED (${entry.fullTextExcludedScreeningTotal.toString()})`,
    ])
    Object.keys(entry.fullTextExcludedScreening).forEach((sc1) =>
      rows.push([
        '',
        sc1,
        entry.fullTextExcludedScreening[sc1].toString(),
        '',
        `${sc1} (${entry.fullTextExcludedScreening[sc1].toString()})`,
      ]),
    )
  }
  rows.push([
    '',
    'INCLUDED',
    entry.included.toString(),
    '',
    `INCLUDED (${entry.included.toString()})`,
  ])
  return rows
}

async function generateScreeningResultsWorksheet(
  worksheet: Worksheet,
  review: Review,
  studiesByType: Record<ReviewItemType, ReviewStudyReference[]>,
) {
  const screeningResults = generateReviewScreeningResults(review)
  let startRow = 2
  const cellsToFill = [2, 3, 4]
  const cslStyle = review.project?.cslStyle
    ? review.project.cslStyle.charAt(0).toUpperCase() +
      review.project.cslStyle.replaceAll('-', ' ').slice(1)
    : '-'
  worksheet.columns = [
    {
      header: 'Search',
      key: 'search',
      width: 10,
      alignment: { vertical: 'middle', wrapText: true },
    },
    {
      header: `Reference of ${cslStyle}`,
      key: 'references',
      width: 50,
      alignment: { wrapText: true },
    },
    { header: 'Source', key: 'source', width: 20 },
    { header: 'Screening', key: 'screening', width: 50 },
  ]

  screeningResults.forEach((s) => {
    let mergedRow = startRow
    if (s.empty) {
      worksheet.insertRow(mergedRow, {
        search: s.searchNumber,
        references: 'The search returned no records',
        source: s.source,
        screening: '-',
      })
      mergedRow++
    } else {
      s.items.forEach((study) => {
        const row = worksheet.insertRow(mergedRow, {
          search: s.searchNumber,
          references:
            studiesByType[study.type].find((a) => a.studyId === study.id)
              ?.inText ??
            '-' +
              '\n' +
              'Title: ' +
              study.title +
              '\n' +
              'Abstract: ' +
              study.abstract,
          source: s.source,
          screening: study.stateText,
        })
        if (study.stateText === 'Included')
          cellsToFill.forEach((c) => {
            row.getCell(c).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: study.fillColor },
            }
          })
        mergedRow++
      })
    }
    worksheet.mergeCells(startRow, 1, mergedRow - 1, 1)
    startRow = mergedRow
  })
  worksheet.getColumn(1).alignment = { vertical: 'middle', wrapText: true }
  worksheet.getColumn(2).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(3).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(4).alignment = { vertical: 'top', wrapText: true }

  return worksheet
}

function generateSearchesWorksheet(worksheet: Worksheet, review: Review) {
  const searchesResults = generateReviewSearchsAndLimtsResults(
    review,
    true,
  ).sort((a, b) => b.searchNumber - a.searchNumber)
  worksheet.columns = [
    {
      header: 'Search',
      key: 'search',
      width: 10,
      alignment: { vertical: 'middle', wrapText: true },
    },
    {
      header: 'Source',
      key: 'source',
      width: 50,
      alignment: { wrapText: true },
    },
    { header: 'Search Date', key: 'searchDate', width: 20 },
    {
      header: 'Person undertaking the search',
      key: 'personUndertakingSearch',
      width: 50,
    },
    {
      header: 'Query',
      key: 'query',
      width: 50,
    },
    {
      header: 'Filters',
      key: 'filters',
      width: 50,
    },
    {
      header: 'Search Details',
      key: 'searchDetails',
      width: 50,
    },
    {
      header: 'Screening summary',
      key: 'screeningSummary',
      width: 50,
    },
  ]

  const startRow = 2

  searchesResults.forEach((s) => {
    worksheet.insertRow(startRow, {
      search: s.searchNumber,
      source: s.source,
      personUndertakingSearch: s.personUndertakingSearch,
      searchDate: s.searchDate,
      query: s.query,
      filters: s.filters,
      searchDetails: s.searchDetails,
      screeningSummary: s.empty
        ? 'The search returned no records'
        : `Hits: ${s.statSummary.hits} \nDuplicates: ${s.statSummary.duplicated} \nExcluded: ${s.statSummary.excluded} \n${s.statSummary.exclusionSummarytext}\nIncluded:${s.statSummary.included}`,
    })
  })
  worksheet.insertRow(searchesResults.length + startRow, {
    search: ' - : not applicable.',
  })
  // merge by start row, start column, end row, end column (equivalent to K10:M12)
  worksheet.mergeCells(
    searchesResults.length + startRow,
    1,
    searchesResults.length + startRow,
    8,
  )
  worksheet.getColumn(1).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(2).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(3).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(4).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(5).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(6).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(7).alignment = { vertical: 'top', wrapText: true }
  worksheet.getColumn(8).alignment = { vertical: 'top', wrapText: true }

  return worksheet
}
