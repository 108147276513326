<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    class="stroke-current"
  >
    <path
      d="M7.68921 11.502V14.002M7.68921 11.502V5.50195C7.68921 4.67353 8.36078 4.00195 9.18921 4.00195C10.0176 4.00195 10.6892 4.67353 10.6892 5.50195M7.68921 11.502C7.68921 10.6735 7.01764 10.002 6.18921 10.002C5.36078 10.002 4.68921 10.6735 4.68921 11.502V13.502C4.68921 17.6441 8.04707 21.002 12.1892 21.002C16.3313 21.002 19.6892 17.6441 19.6892 13.502V8.50195C19.6892 7.67353 19.0176 7.00195 18.1892 7.00195C17.3608 7.00195 16.6892 7.67353 16.6892 8.50195M10.6892 5.50195V11.002M10.6892 5.50195V4.50195C10.6892 3.67353 11.3608 3.00195 12.1892 3.00195C13.0176 3.00195 13.6892 3.67353 13.6892 4.50195V5.50195M13.6892 5.50195V11.002M13.6892 5.50195C13.6892 4.67353 14.3608 4.00195 15.1892 4.00195C16.0176 4.00195 16.6892 4.67353 16.6892 5.50195V8.50195M16.6892 8.50195V11.002"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
