<template>
  <div class="grid grid-cols-[2fr_2fr_auto] group">
    <TextInput
      v-model="internalEvaluator.role"
      placeholder="Author"
      input-classes="group-last:hover:border-slate-300 hover:border-slate-300 group-last:hover:border-r-transparent hover:border-b-transparent hover:border-r-transparent focus:ring-1 focus:ring-inset group-last:focus:border-blue-500 group-first:rounded-tl-md rounded-none border-b-transparent border-r-transparent group-last:border-b group-last:border-r-transparent group-last:border-slate-300 group-last:rounded-bl-md   group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset"
      :disabled="review.isPlanReadonly.value"
      @enter="updateEvaluator"
      @blur="updateEvaluator"
    />
    <EvaluatorsCombobox
      v-model="internalEvaluator.name"
      input-classes="group-last:hover:border-slate-300  focus:ring-1 focus:ring-inset group-last:focus:border-blue-500 group-first:rounded-tr-md rounded-none border-b-transparent group-last:rounded-br-md group-last:border-slate-300 group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset group-last:border-l"
      :disabled="review.isPlanReadonly.value"
      @update:model-value="updateEvaluator"
    />
    <button
      v-if="!review.isPlanReadonly.value"
      :disabled="review.isPlanReadonly.value"
      @click="deleteEvaluator"
    >
      <TrashIcon class="w-4 h-4 text-red-600 ml-2" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { Evaluator } from '@core/domain/models/evaluator.model'
import { ReviewKey } from '../../use-review'
import { injectStrict } from '@app/utils/injectStrict'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ref } from 'vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import EvaluatorsCombobox from './EvaluatorsCombobox.vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'

const props = defineProps<{
  evaluator: Evaluator
}>()

const loading = useLoading()
const snackbar = useSnackbar()
const internalEvaluator = ref({
  role: props.evaluator.role,
  name: props.evaluator.name,
  id: props.evaluator.id,
})
const review = injectStrict(ReviewKey)

async function updateEvaluator() {
  loading.start()
  try {
    await review.updateEvaluator(internalEvaluator.value)
    snackbar.success('Evaluator updated')
  } catch (e) {
    snackbar.error('Failed to update evaluator')
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteEvaluator() {
  loading.start()
  try {
    await review.deleteEvaluator(internalEvaluator.value.id)
    snackbar.success('Evaluator deleted')
  } catch (e) {
    snackbar.error('Failed to delete evaluator')
    throw e
  } finally {
    loading.stop()
  }
}
</script>
