<template>
  <div>
    <div class="mb-8 text-blue-700 font-semibold tracking-tighter text-lg">
      Device under assessment
    </div>
    <div class="flex gap-6">
      <CallToAction
        v-for="level in [
          {
            label: 'Basic',
            value: PmcLevel.minimal,
            description:
              'A search that meets the essential minimum requirements to ensure compliance.',
          },
          {
            label: 'Standard',
            value: PmcLevel.standard,
            description:
              'A thorough and comprehensive search covering a wide range of relevant sources.',
          },
          {
            label: 'Extensive',
            value: PmcLevel.advanced,
            description:
              'An exhaustive search aimed at identifying all available data on the device.',
          },
        ]"
        :key="level.value"
        :is-disabled="level.value !== PmcLevel.minimal"
        :is-active="pmcLevel === level.value"
        :title="level.value !== PmcLevel.minimal ? 'Coming soon' : ''"
        :subtitle="level.label"
        :description="level.description"
        @handle="selectLevel(level.value)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { CreateReviewKey, PmcLevel } from '../../../useCreateProjectReview'
import { nextTick } from 'vue'
import CallToAction from '../../components/CallToAction.vue'

const { pmcLevel, goToNextStep } = injectStrict(CreateReviewKey)

function selectLevel(level: PmcLevel) {
  pmcLevel.value = level
  nextTick(() => goToNextStep())
}
</script>
