<template>
  <div class="grid grid-cols-[2fr_2fr_auto] group">
    <TextInput
      v-model="newEvaluator.role"
      placeholder="Author"
      input-classes="hover:border-slate-300  hover:border-r-transparent focus:ring-1 focus:ring-inset rounded-none border-r-transparent rounded-bl-md group-first:rounded-tl-md"
      :disabled="review.isPlanReadonly.value"
      @enter="createEvaluator"
    />

    <EvaluatorsCombobox
      v-model="newEvaluator.name"
      input-classes="hover:border-slate-300 focus:ring-1 focus:ring-inset hover:border-blue-500 focus:border-blue-500 rounded-none rounded-br-md group-first:rounded-tr-md "
      @update:model-value="createEvaluator"
    />
    <button
      v-if="!review.isPlanReadonly.value"
      class="ring-0"
      @click="deleteEvaluator"
    >
      <TrashIcon class="w-4 h-4 text-red-600 ml-2" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ref } from 'vue'
import { ReviewKey } from '../../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import EvaluatorsCombobox from './EvaluatorsCombobox.vue'

const newEvaluator = ref<{
  name: string
  role: string
}>({
  name: '',
  role: '',
})

const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

async function createEvaluator() {
  loading.start()
  try {
    await review.createEvaluator({
      name: newEvaluator.value.name,
      role: newEvaluator.value.role,
    })
    emit('hide')
    snackbar.success('Evaluator created')
  } catch (_e) {
    snackbar.error('Failed to create evaluator')
  } finally {
    loading.stop()
  }
}

const emit = defineEmits(['hide'])

const deleteEvaluator = () => {
  emit('hide')
}
</script>
