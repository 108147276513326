<template>
  <template v-if="reviewExists">
    <teleport to="#header-middle">
      <ReviewTitle />
    </teleport>
    <teleport to="#toolbar">
      <Tabs v-model:tab="activeStep" :tabs="tabs" />
    </teleport>
    <KeepAlive>
      <component :is="reviewStepComponentName" />
    </KeepAlive>
  </template>
</template>

<script lang="ts" setup>
import useReview, { ReviewKey } from '@app/views/Review/use-review'
import { computed, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ReviewTitle from './Overview/ReviewTitle.vue'
import useLoading from '@app/composables/use-loading'
import Execute from './Execute/Execute.vue'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import Export from './Export/Export.vue'
import Plan from './Plan/Plan.vue'
import { ReviewStep } from './review.types'
import { isAxiosError } from 'axios'

const router = useRouter()
const route = useRoute()
const loading = useLoading()
const reviewExists = ref(false)
loading.start()
const review = useReview(parseInt(route.params.id as string))
provide(ReviewKey, review)
loading.stop()

const activeStep = ref(1)
await review
  .refresh()
  .then(() => {
    activeStep.value =
      review.entity.value.plan?.lockState === ReviewLockState.LOCKED
        ? ReviewStep.execute
        : ReviewStep.plan
    reviewExists.value = true
  })
  .catch((e) => {
    if (isAxiosError(e) && e.response?.status === 404) {
      router.push('/404')
    } else throw e
  })

const reviewStepComponentName = computed(() => {
  switch (activeStep.value) {
    case ReviewStep.plan:
      return Plan
    case ReviewStep.execute:
      return Execute
    case ReviewStep.export:
      return Export
    default:
      return Plan
  }
})

const tabs = computed(() => {
  if (!review.entity.value) {
    return []
  }
  const tabs = [
    {
      number: ReviewStep.plan,
      title: 'Plan',
      subtitle: 'Outline objectives and criteria Edited',
    },
    {
      number: ReviewStep.execute,
      title: 'Execute',
      subtitle: 'Perform screening and analysis',
    },
    {
      number: ReviewStep.export,
      title: 'Export',
      subtitle: 'Generate and share results',
      isDisabled:
        (review.entity.value.plan?.lockState !== ReviewLockState.DISABLED &&
          !review.isPlanReadonly.value) ||
        review.isArchived.value,
    },
  ]

  return tabs
})
</script>

<style>
.ripple {
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
