<template>
  <div
    :id="importSource.id"
    class="space-y-6 px-3 py-7"
    :data-navigation="importSource.name"
  >
    <div class="">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <div
            class="flex items-center gap-2 text-blue-800 text-base font-semibold"
          >
            <div class="flex items-center gap-2">
              <template v-if="isPlanEditable">
                <a
                  v-if="
                    Object.values(BuiltInImportSourceId).includes(
                      importSource.id as BuiltInImportSourceId,
                    ) &&
                    ![
                      BuiltInImportSourceId.HAND_SEARCH,
                      BuiltInImportSourceId.CITATION_SEARCH,
                      BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
                    ].includes(importSource.id as BuiltInImportSourceId)
                  "
                  :href="importSource.url"
                  class="truncate flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
                  target="_blank"
                >
                  <SourceIcon :source="importSource" :icon-url="iconUrl" />
                  {{ importSource.name }}
                  <ExternalLinkIcon class="w-5 text-blue-800" />
                </a>
                <Modal
                  v-else-if="
                    [BuiltInImportSourceId.HAND_SEARCH].includes(
                      importSource.id as BuiltInImportSourceId,
                    )
                  "
                >
                  <template #activator="{ show }">
                    <button
                      class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
                      @click="() => (isPlanEditable ? show() : null)"
                    >
                      <SourceIcon :source="importSource" :icon-url="iconUrl" />
                      {{ importSource.name }}
                      <ImportIcon class="w-5 text-blue-800" />
                    </button>
                  </template>
                  <template #content="{ hide }">
                    <HandSearch @hide="hide" />
                  </template>
                </Modal>
                <Modal
                  v-else-if="
                    importSource.type === ImportSourceType.OTHER_SOURCE
                  "
                >
                  <template #activator="{ show }">
                    <button
                      class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
                      @click="() => (isPlanEditable ? show() : null)"
                    >
                      <SourceIcon :source="importSource" :icon-url="iconUrl" />
                      {{ importSource.name }}
                      <ImportIcon class="w-5 text-blue-800" />
                    </button>
                  </template>
                  <template #content="{ hide }">
                    <CustomSource
                      :import-source="importSource"
                      :readonly="isPlanEditable"
                      @hide="hide"
                    />
                  </template>
                </Modal>
                <Modal
                  v-else-if="
                    [BuiltInImportSourceId.FIELD_SAFETY_NOTICES].includes(
                      importSource.id as BuiltInImportSourceId,
                    )
                  "
                >
                  <template #activator="{ show }">
                    <button
                      class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
                      @click="() => (isPlanEditable ? show() : null)"
                    >
                      <SourceIcon :source="importSource" :icon-url="iconUrl" />
                      {{ importSource.name }}
                      <ArrowsPointingOutIcon class="w-5 text-blue-800" />
                    </button>
                  </template>
                  <template #content="{ hide }">
                    <FsnSearch @hide="hide" />
                  </template>
                </Modal>
              </template>
              <template v-else>
                <SourceIcon :source="importSource" :icon-url="iconUrl" />
                {{ importSource.name }}
              </template>
            </div>
          </div>
        </div>
        <div class="flex gap-6">
          <template
            v-if="
              ![
                BuiltInImportSourceId.HAND_SEARCH,
                BuiltInImportSourceId.CITATION_SEARCH,
                BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
              ].includes(importSource.id as BuiltInImportSourceId) &&
              isBuiltInImportSource(importSource.id)
            "
          >
            <a
              v-if="!isExtensionInstalled"
              href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
              target="_blank"
              rel="noopener"
              class="flex gap-2 text-blue-500 text-sm font-medium leading-[-0.13px]"
            >
              <ChromeLogoIcon />
              <span class="block flex-1 truncate">
                Install the chrome extension to import from
                {{ importSource.name }}
              </span>
              <ExternalLinkIcon class="w-4" />
            </a>
          </template>
          <button
            v-if="
              (review.searchesBySource.value[importSource.id]?.length ?? 0) <=
                0 && !review.isPlanReadonly.value
            "
            class="bg-red-500 px-2 py-1 rounded"
            @click="removeImportSourceFromPlan(importSource.id)"
          >
            <TrashIcon class="w-4 h-4 text-white" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="
        isSelected && review.searchesBySource.value[importSource.id]?.length > 0
      "
    >
      <table
        aria-describedby="searches tables"
        class="w-full border-collapse border-spacing-7"
      >
        <thead>
          <tr class="text-sm border-b border-slate-400">
            <th class="text-left align-top w-[57px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Search
              </div>
            </th>
            <th class="text-left align-top w-[83px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Date
              </div>
            </th>
            <th
              v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
              class="text-left align-top w-12"
            >
              <div class="p-2 text-primary">Parent</div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH &&
                importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
              "
              class="text-left align-top w-[112px]"
            >
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Search details
              </div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
              "
              class="text-left align-top"
            >
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Query
              </div>
            </th>

            <th class="text-left align-top">
              <div
                v-if="
                  importSource.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES
                "
                class="px-2 pb-2 text-blue-800 font-medium text-xs"
              >
                Databases
              </div>
            </th>
            <th class="align-top text-left w-[59px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Results
              </div>
            </th>
            <th class="text-left align-top w-8">
              <div class="px-2 pb-2"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(search, index) in getSourceSearches(importSource.id)"
            :key="importSource.id + index"
          >
            <SearchRow
              :search="search"
              :search-number="index + 1"
              :import-source="importSource"
            />
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="w-full text-slate-500 text-sm font-[400] px-2 py-6 bg-slate-50 border border-slate-200 rounded-xl flex flex-col items-center text-center justify-center gap-4"
    >
      <SearchNotFoundIcon />
      <div class="flex flex-col items-center gap-2">
        <p
          v-if="
            review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
            importSource.id === BuiltInImportSourceId.HAND_SEARCH
          "
        >
          You haven’t imported any record yet.
        </p>
        <p v-else>You haven’t imported any search yet.</p>
      </div>
    </div>

    <slot
      v-if="
        review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
        importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
      "
      name="bottom"
    />
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { Search } from '@core/domain/models/search.model'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed } from 'vue'
import { ImportSource } from '@core/domain/models/import-source.model'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import useWebExtension from '@app/composables/useWebExtension'
import SearchRow from './ImportSourceCard/SearchRow.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import ChromeLogoIcon from '@app/components/Icons/ChromeLogoIcon.vue'
import ExternalLinkIcon from '@app/components/Icons/ExternalLinkIcon.vue'
import SearchNotFoundIcon from '@app/components/Icons/SearchNotFoundIcon.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import HandSearch from './HandSearch.vue'
import ImportIcon from '@app/components/Icons/ImportIcon.vue'
import FsnSearch from '../SourcesList/FsnSearch/FsnSearch.vue'
import { ArrowsPointingOutIcon } from '@heroicons/vue/24/outline'
import SourceIcon from './SourceIcon.vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import CustomSource from './CustomSource.vue'

const { isExtensionInstalled } = useWebExtension()

const builtInImportSources = useBuiltInImportSources()

const isPlanEditable = computed(() => !review.isPlanReadonly.value)

const props = defineProps<{
  importSource: ImportSource
  tooltip?: {
    text: string
    url?: string
  }
  iconUrl?: string
  query?: string
}>()

const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)
const isSelected = computed(() =>
  review.entity?.value?.plan?.importPlan?.importSources?.some(
    (ri) => ri.id === props.importSource.id,
  ),
)

const emit = defineEmits<(e: 'importSourceRemoved') => void>()

async function removeImportSourceFromPlan(sourceId: string) {
  try {
    loading.start()
    emit('importSourceRemoved')
    await review.removeImportSourceFromPlan(sourceId)
    snackbar.show(SnackbarState.SUCCESS, 'Source removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function getSourceSearches(importSourceId: string): Search[] {
  return review.searchesBySource.value[importSourceId] ?? []
}

const isBuiltInImportSource = (importSourceId: string) => {
  return !!builtInImportSources.findById(importSourceId)
}
</script>
