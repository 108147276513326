<template>
  <div class="p-6 max-w-[380px]">
    <GenericAutoComplete
      :item-options="{
        displayProperty: 'text',
        valueProperty: 'value',
        filterProperties: ['text'],
      }"
      :items="[
        {
          text: 'DD/MM/YYYY',
          value: 'dd/MM/yyyy',
          title: format(new Date(), 'dd/MM/yyyy'),
        },
        {
          text: 'MM/DD/YYYY',
          value: 'MM/dd/yyyy',
          title: format(new Date(), 'MM/dd/yyyy'),
        },
      ]"
      :model-value="settings.dateFormat.value"
      placeholder="Date Format"
      label="Date Format"
      @update:model-value="onDateFormatChange"
    />
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useSettings } from '@app/composables/use-settings'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'

const settings = useSettings()
function onDateFormatChange(format?: unknown): void {
  if (!format || typeof format !== 'string') return
  settings.dateFormat.value = format
}
</script>
