import { ImportSource } from '@core/domain/models/import-source.model'
import { LiteratureReviewPlan } from '@core/domain/models/literature-review-plan.model'
import { Review } from '@core/domain/models/review.model'
import { ReviewPreset } from '@core/domain/types/review-preset.type'

// Constants and Types
const SUPPORTED_SOURCES = [
  'pubmed',
  'google-scholar',
  'pmc',
  'europe-pmc',
  'open-alex',
  'safety-information',
] as const

type SupportedSourceId = (typeof SUPPORTED_SOURCES)[number]

const SUPPORTED_PRESETS = [
  ReviewPreset.DEVICE_SPECIFIC_SEARCH,
  ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH,
] as const

type SupportedPreset = (typeof SUPPORTED_PRESETS)[number]

// Helper functions
const formatName = (name: string): string => {
  const cleanName = name.replace(/[^a-zA-Z0-9]+/g, ' ').trim()
  return `"${cleanName}"`
}

const formatNamesList = (names: string[]): string[] =>
  names.filter((name) => name.trim()).map((name) => formatName(name))

const buildQueryPart = (names: string[]): string => {
  if (names.length === 0) return ''
  const separator = ' OR '
  const query = names.join(separator)
  return names.length > 1 ? `(${query})` : query
}

const isValidSource = (
  source: ImportSource,
): source is ImportSource & { id: SupportedSourceId } =>
  SUPPORTED_SOURCES.includes(source.id as SupportedSourceId)

const isValidPlan = (
  plan: LiteratureReviewPlan | undefined,
): plan is LiteratureReviewPlan & { preset: SupportedPreset } =>
  !!plan && SUPPORTED_PRESETS.includes(plan.preset as SupportedPreset)

const combineQueries = (
  deviceQuery: string,
  manufacturerQuery: string,
  sourceId: string,
): string => {
  if (!deviceQuery) return ''
  if (sourceId === 'google-scholar') {
    return manufacturerQuery
      ? `${deviceQuery} ${manufacturerQuery}`
      : deviceQuery
  } else {
    return manufacturerQuery
      ? `${deviceQuery} AND ${manufacturerQuery}`
      : deviceQuery
  }
}

// Main function
export function generateSearchQuery(
  importSource: ImportSource,
  review: Review,
): string {
  if (!isValidSource(importSource)) return ''

  const plan = review.plan
  if (!isValidPlan(plan)) return ''

  const deviceNames = formatNamesList([
    plan.deviceName ?? '',
    ...(plan.alternativeDeviceNames ?? []),
  ])

  const manufacturerNames = formatNamesList([
    plan.manufacturerName ?? '',
    ...(plan.alternativeManufacturerNames ?? []),
  ])

  const deviceQuery = buildQueryPart(deviceNames)
  const manufacturerQuery = buildQueryPart(manufacturerNames)

  return combineQueries(deviceQuery, manufacturerQuery, importSource.id)
}
