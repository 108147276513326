<template>
  <svg
    id="flag-icons-nl"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path fill="#ae1c28" d="M0 0h512v170.7H0z" />
    <path fill="#fff" d="M0 170.7h512v170.6H0z" />
    <path fill="#21468b" d="M0 341.3h512V512H0z" />
  </svg>
</template>
