<template>
  <svg
    width="138"
    height="34"
    viewBox="0 0 138 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4269_17329)">
      <path
        d="M50.6964 17.2279H42.0735C42.2264 17.8944 42.6218 18.4792 43.1819 18.8672C43.818 19.2916 44.5708 19.5038 45.3336 19.4738C45.8887 19.4934 46.4423 19.4043 46.9636 19.2115C47.4379 19.0194 47.8699 18.7354 48.2351 18.3755L49.9955 20.2935C49.397 20.931 48.6658 21.4277 47.8543 21.7479C47.0427 22.0681 46.1708 22.204 45.301 22.1459C44.1204 22.1667 42.9522 21.9025 41.8942 21.3755C40.9521 20.9139 40.1604 20.1916 39.6121 19.2935C39.0711 18.3707 38.7947 17.3153 38.8134 16.2443C38.7993 15.1811 39.0695 14.1337 39.5958 13.2115C40.1146 12.3205 40.8713 11.5936 41.78 11.1131C42.7295 10.6279 43.7795 10.375 44.8445 10.375C45.9096 10.375 46.9596 10.6279 47.909 11.1131C48.8012 11.5783 49.538 12.2965 50.0281 13.1787C50.5645 14.1385 50.8347 15.2256 50.8105 16.3263C50.8105 16.3263 50.729 16.6705 50.6964 17.2279ZM42.97 13.5558C42.455 13.9977 42.1235 14.6178 42.0409 15.2935H47.6482C47.5581 14.6202 47.2279 14.0026 46.7191 13.5558C46.1894 13.1258 45.525 12.8991 44.8445 12.9164C44.1636 12.8962 43.4982 13.1232 42.97 13.5558Z"
        fill="#172554"
      />
      <path
        d="M64.0462 10.5742L59.2376 22.0496H55.8308L51.0547 10.5742H54.4615L57.6075 18.3611L60.8676 10.5742H64.0462Z"
        fill="#172554"
      />
      <path
        d="M65.559 8.44177C65.3767 8.27646 65.2315 8.07397 65.1331 7.84783C65.0348 7.6217 64.9855 7.37708 64.9884 7.13027C64.9852 6.88102 65.0344 6.63388 65.1327 6.40505C65.231 6.17623 65.3763 5.97087 65.559 5.8024C65.9816 5.46735 66.5041 5.28516 67.0423 5.28516C67.5804 5.28516 68.1029 5.46735 68.5256 5.8024C68.7088 5.95988 68.855 6.15623 68.9536 6.37734C69.0523 6.59844 69.1009 6.83879 69.0961 7.08109C69.1009 7.33539 69.0526 7.58785 68.9543 7.82222C68.856 8.05658 68.71 8.26767 68.5256 8.44177C68.3279 8.62954 68.0947 8.77555 67.84 8.87127C67.5852 8.967 67.314 9.0105 67.0423 8.99915C66.4939 9.01839 65.9604 8.81792 65.559 8.44177ZM65.3959 10.5729H68.656V22.0483H65.3959V10.5729Z"
        fill="#172554"
      />
      <path
        d="M83.3597 6.23047V22.0173H80.1974V20.7059C79.757 21.2101 79.208 21.6065 78.5922 21.865C77.9764 22.1235 77.31 22.2374 76.6439 22.1977C75.629 22.2127 74.6282 21.9582 73.7424 21.46C72.8815 20.988 72.1741 20.2765 71.7048 19.4108C71.1884 18.4563 70.9298 17.3823 70.955 16.296C70.9281 15.2147 71.1868 14.1456 71.7048 13.1977C72.1693 12.3242 72.8776 11.6062 73.7424 11.1321C74.6295 10.6388 75.6304 10.39 76.6439 10.4108C77.2773 10.3757 77.9107 10.4808 78.4994 10.7188C79.088 10.9567 79.6176 11.3216 80.0507 11.7878V6.23047H83.3597ZM79.3009 18.6075C79.8276 17.9536 80.115 17.1376 80.115 16.296C80.115 15.4545 79.8276 14.6384 79.3009 13.9845C78.7414 13.4397 77.9932 13.1351 77.2144 13.1351C76.4356 13.1351 75.6874 13.4397 75.128 13.9845C74.5895 14.6331 74.2946 15.4511 74.2946 16.296C74.2946 17.1409 74.5895 17.959 75.128 18.6075C75.6874 19.1523 76.4356 19.457 77.2144 19.457C77.9932 19.457 78.7414 19.1523 79.3009 18.6075Z"
        fill="#172554"
      />
      <path
        d="M97.4913 17.2294H88.9172C89.0701 17.8959 89.4655 18.4807 90.0257 18.8687C90.6618 19.2931 91.4146 19.5053 92.1773 19.4753C92.7325 19.4949 93.2861 19.4058 93.8074 19.213C94.2846 19.0173 94.7214 18.7338 95.0951 18.377L96.8393 20.295C96.2422 20.9343 95.5112 21.4322 94.6993 21.7526C93.8874 22.073 93.0149 22.2078 92.1448 22.1474C90.9642 22.1682 89.796 21.904 88.738 21.377C87.7959 20.9154 87.0042 20.1931 86.4558 19.295C85.9149 18.3722 85.6385 17.3168 85.6571 16.2458C85.6431 15.1826 85.9133 14.1352 86.4396 13.213C86.9584 12.322 87.7151 11.5951 88.6238 11.1146C89.5758 10.6029 90.6416 10.3434 91.7209 10.3605C92.7759 10.3401 93.8188 10.5883 94.7528 11.0819C95.6449 11.547 96.3817 12.2652 96.8719 13.1474C97.4082 14.1072 97.6784 15.1942 97.6543 16.295C97.6543 16.295 97.5239 16.672 97.4913 17.2294ZM89.7648 13.5573C89.2499 13.9992 88.9184 14.6193 88.8358 15.295H94.4431C94.353 14.6217 94.0228 14.0041 93.514 13.5573C92.9761 13.1425 92.3172 12.9177 91.6394 12.9177C90.9616 12.9177 90.3027 13.1425 89.7648 13.5573Z"
        fill="#172554"
      />
      <path
        d="M110.109 11.6704C110.57 12.1798 110.924 12.7782 111.148 13.4292C111.372 14.0802 111.462 14.7702 111.413 15.4574V22.0147H108.153V15.9819C108.211 15.255 108.002 14.5319 107.566 13.9492C107.356 13.7286 107.103 13.5548 106.822 13.4391C106.541 13.3233 106.239 13.2681 105.936 13.277C105.569 13.2584 105.203 13.3174 104.86 13.4501C104.518 13.5828 104.207 13.7864 103.947 14.0475C103.412 14.6929 103.144 15.5203 103.198 16.359V22.0147H99.9375V10.5393H103.083V11.8836C103.533 11.3892 104.091 11.0075 104.714 10.7688C105.393 10.5003 106.119 10.3667 106.849 10.3754C108.061 10.3676 109.229 10.8316 110.109 11.6704Z"
        fill="#172554"
      />
      <path
        d="M116.729 21.4248C115.798 20.9534 115.014 20.233 114.463 19.3429C113.93 18.4163 113.648 17.3645 113.648 16.2936C113.648 15.2228 113.93 14.171 114.463 13.2445C115.014 12.3543 115.798 11.6339 116.729 11.1625C117.738 10.6471 118.858 10.3882 119.989 10.4084C121.08 10.3796 122.159 10.6397 123.119 11.1625C123.995 11.6381 124.689 12.3937 125.091 13.31L122.532 14.7035C122.304 14.2108 121.94 13.7944 121.484 13.5037C121.027 13.2131 120.497 13.0605 119.957 13.0641C119.562 13.0525 119.169 13.1222 118.801 13.2688C118.434 13.4153 118.101 13.6358 117.821 13.9166C117.532 14.2242 117.308 14.5877 117.162 14.985C117.016 15.3823 116.952 15.8052 116.974 16.2281C116.942 17.0857 117.247 17.9215 117.821 18.5559C118.104 18.8315 118.438 19.0474 118.805 19.191C119.172 19.3346 119.563 19.4029 119.957 19.392C120.498 19.3998 121.03 19.2488 121.487 18.9577C121.944 18.6666 122.307 18.2479 122.532 17.7527L125.091 19.1625C124.674 20.0647 123.984 20.8108 123.119 21.2936C122.161 21.8222 121.082 22.0879 119.989 22.0642C118.867 22.1194 117.748 21.9 116.729 21.4248Z"
        fill="#172554"
      />
      <path
        d="M137.934 17.2279H129.328C129.48 17.8944 129.876 18.4792 130.436 18.8672C131.071 19.2937 131.825 19.5061 132.588 19.4738C133.143 19.4917 133.696 19.4027 134.218 19.2115C134.692 19.0194 135.124 18.7354 135.489 18.3755L137.25 20.2935C136.651 20.931 135.92 21.4277 135.108 21.7479C134.297 22.0681 133.425 22.204 132.555 22.1459C131.374 22.1686 130.206 21.9044 129.148 21.3755C128.203 20.9185 127.41 20.1952 126.866 19.2935C126.32 18.372 126.038 17.3168 126.051 16.2443C126.043 15.1797 126.319 14.1324 126.85 13.2115C127.369 12.3205 128.125 11.5936 129.034 11.1131C129.984 10.6279 131.034 10.375 132.099 10.375C133.164 10.375 134.214 10.6279 135.163 11.1131C136.051 11.5837 136.787 12.3005 137.282 13.1787C137.814 14.1398 138.078 15.227 138.048 16.3263C138.048 16.3263 137.983 16.6705 137.934 17.2279ZM130.224 13.5558C129.703 13.9924 129.37 14.6152 129.295 15.2935H134.902C134.814 14.6159 134.477 13.996 133.957 13.5558C133.422 13.1424 132.765 12.9182 132.091 12.9182C131.416 12.9182 130.759 13.1424 130.224 13.5558Z"
        fill="#172554"
      />
      <path
        d="M32.5045 19.9519C32.2484 19.5489 31.9035 19.2106 31.4964 18.9632C31.0893 18.7159 30.6311 18.5662 30.1573 18.5257H29.8801C29.2402 18.5573 28.6237 18.7778 28.1077 19.1597C27.5916 19.5417 27.1989 20.0681 26.9786 20.6732C26.4316 22.2024 25.5388 23.583 24.3706 24.706C23.1168 25.9221 21.5855 26.8103 19.911 27.2925C18.2364 27.7747 16.4697 27.8361 14.766 27.4716C13.0623 27.107 11.4735 26.3276 10.1392 25.2016C8.8048 24.0756 7.76562 22.6375 7.11277 21.0134C6.45991 19.3893 6.21334 17.6288 6.39469 15.8865C6.57605 14.1442 7.17974 12.4734 8.15289 11.0205C9.12603 9.56755 10.4388 8.377 11.9762 7.55331C13.5135 6.72962 15.2283 6.29794 16.9701 6.29614C17.6261 6.29959 18.2805 6.35995 18.9262 6.47649C19.4809 6.55929 20.0076 6.77537 20.4616 7.10641C20.9156 7.43744 21.2836 7.87368 21.5343 8.37814C21.8277 9.31256 21.0616 10.3453 20.3443 11.0666L19.1055 12.329C18.4386 12.0104 17.7085 11.8478 16.9701 11.8535C15.8726 11.8501 14.8035 12.2038 13.9223 12.8618C13.0411 13.5198 12.3952 14.4468 12.0811 15.5044C11.767 16.5621 11.8015 17.6935 12.1795 18.7298C12.5574 19.766 13.2586 20.6514 14.1782 21.2539C15.0978 21.8564 16.1866 22.1435 17.2818 22.0724C18.3771 22.0013 19.42 21.5758 20.255 20.8594C21.0899 20.143 21.672 19.1741 21.9143 18.0976C22.1567 17.021 22.0462 15.8945 21.5995 14.8863L28.1197 8.32896C28.6142 7.74052 28.8646 6.98329 28.8191 6.21419C28.7736 5.4451 28.4356 4.72307 27.8752 4.19778C24.8457 1.51472 20.9416 0.0445516 16.905 0.0666518C14.1601 0.0569864 11.4542 0.719042 9.02054 1.99571C6.5869 3.27238 4.49862 5.12534 2.93586 7.39472C1.37311 9.66411 0.382795 12.2818 0.050345 15.0219C-0.282105 17.7621 0.0533201 20.5425 1.02764 23.1232C2.00196 25.7039 3.58589 28.0075 5.6429 29.8352C7.6999 31.663 10.1682 32.9599 12.8348 33.6143C15.5013 34.2687 18.2862 34.2608 20.9491 33.5913C23.612 32.9218 26.073 31.611 28.1197 29.7716L28.8369 29.0994C30.6092 27.3162 31.9673 25.161 32.8142 22.788C32.9858 22.3237 33.0471 21.8254 32.9933 21.333C32.9396 20.8407 32.7722 20.3677 32.5045 19.9519Z"
        fill="#1E40AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_4269_17329">
        <rect width="138" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
