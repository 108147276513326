<template>
  <div
    class="sticky top-0 w-96 flex flex-col h-full overflow-hidden rounded-lg border border-slate-200"
  >
    <div
      class="px-4 py-3 bg-blue-800 text-white text-sm font-medium flex items-center gap-2"
    >
      <CircleStackIcon class="w-4" /> Sources
    </div>
    <div class="overflow-y-auto">
      <div class="divide-y">
        <SourcesPanel
          v-for="type in sourceTypes"
          :key="type"
          :source-type="type"
        />

        <Modal ref="addCustomSource">
          <template #activator="{ show }">
            <button
              class="flex justify-between items-center w-full pl-4 py-[10px] pr-2 text-blue-800 text-sm font-semibold !border-2 rounded-md border-dashed border-blue-300 bg-blue-50 rounded-tl-none rounded-tr-none"
              @click="() => (isPlanEditable ? show() : null)"
            >
              <span class="flex items-center">
                <PlusIcon class="text-blue-800 w-4" />
                <div>Add custom source / hand search</div>
              </span>

              <ArrowsPointingInIcon class="w-4 h-4 text-primary" />
            </button>
          </template>
          <template #content="{ hide }">
            <CustomSourceHandSearch :readonly="isPlanEditable" @hide="hide" />
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { CircleStackIcon } from '@heroicons/vue/24/solid'
import SourcesPanel from './SourcesPanel.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import ArrowsPointingInIcon from '@app/components/Icons/ArrowsPointingInIcon.vue'
import CustomSourceHandSearch from '../Components/CustomSourceHandSearch.vue'
import { PlusIcon } from '@heroicons/vue/24/outline'

const sourceTypes = [
  'Cross-publisher',
  'Single-publisher',
  'Systematic reviews',
  'Clinical trials',
  'Incidents',
  'Safety information',
  'Standards',
  'Guidance',
]
const review = injectStrict(ReviewKey)

const isPlanEditable = computed(() => !review.isPlanReadonly.value)
</script>

<style scoped>
.tree {
  position: relative;
  padding-left: 20px;
}

.item {
  position: relative;
}

.sub-item {
  position: relative;
}

.sub-item::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item span::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  width: 20px;
  height: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item:last-child::before {
  height: 50%;
}
</style>
