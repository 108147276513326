function getLuminance(r: number, g: number, b: number): number {
  const [rs, gs, bs] = [r / 255, g / 255, b / 255].map((val) =>
    val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4),
  )
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs
}

export function generateColorFromString(str: string): string {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let r = Math.abs((hash & 0xff0000) >> 16)
  let g = Math.abs((hash & 0x00ff00) >> 8)
  let b = Math.abs(hash & 0x0000ff)

  const luminance = getLuminance(r, g, b)
  const contrastRatio = (1 + 0.05) / (luminance + 0.05)

  if (contrastRatio < 4.5) {
    const factor = 0.95
    r = Math.floor(r * factor)
    g = Math.floor(g * factor)
    b = Math.floor(b * factor)
  }

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}
