<template>
  <div
    class="w-screen max-w-[644px] p-6 relative pt-8 bg-slate-100 rounded-2xl"
  >
    <button class="absolute top-3 right-3" @click="emit('hide')">
      <XMarkIcon class="w-4 h-4 text-slate-400" />
    </button>
    <div class="space-y-4">
      <div class="flex gap-1 items-center">
        <DatabaseSolideIcon class="text-blue-800" />
        <div class="text-blue-800 font-semibold text-base">Custom Source</div>
      </div>
      <div class="space-y-2 pb-4">
        <div>
          <DatePicker
            v-model="date"
            label="Search date"
            placeholder="Search date"
            :error="date ? dateValidationError : ''"
            :is-required="true"
          />
        </div>
        <div>
          <TextInput
            v-model="query"
            label="Search"
            placeholder="Search query"
            class="w-full"
            :is-required="importSource.type === ImportSourceType.DATABASE"
          />
        </div>
        <div>
          <TextInput
            v-model="filters"
            label="Filters"
            placeholder="Filters"
            class="w-full"
            can-resize
          />
        </div>

        <div class="flex gap-1">
          <button @click.stop="toggleEmptySearchCheckbox()">
            <Checkbox v-model="emptySearchCheckbox" class="w-5 h-5"></Checkbox>
          </button>
          <p class="text-slate-700">Search returned no records.</p>
        </div>

        <div v-if="emptySearchCheckbox !== CheckboxState.checked">
          <RisDropZone
            ref="risDropZoneComponent"
            v-model="citationFiles"
            v-model:error="citationFilesError"
          />
        </div>
      </div>

      <button
        :disabled="isSubmitDisabled()"
        :class="{
          '!bg-black/5 !text-black/40': isSubmitDisabled(),
        }"
        class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
        @click="
          importRisStudies({
            date,
            files: citationFiles,
            sourceId: importSource.id,
            query,
            filters,
          })
        "
      >
        Import
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import DatabaseSolideIcon from '@app/components/Icons/DatabaseSolideIcon.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import { computed, ref } from 'vue'
import { format } from 'date-fns'
import DatePicker from '@app/components/Global/Inputs/DatePicker.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'

import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import { ImportSource } from '@core/domain/models/import-source.model'
import RisDropZone from './RisDropZone.vue'

const props = defineProps<{
  importSource: ImportSource
  readonly?: boolean
}>()

const date = ref(format(new Date(), 'yyyy-MM-dd'))
const query = ref('')
const filters = ref('')

const dateValidationError = computed<string>(() => {
  return date.value ? '' : 'date is required'
})
const citationFiles = ref<{ isValid: boolean; file: File }[]>([])
const citationFilesError = ref('')
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const emptySearchCheckbox = ref<CheckboxState>(CheckboxState.uncheked)

function toggleEmptySearchCheckbox() {
  if (emptySearchCheckbox.value === CheckboxState.checked)
    emptySearchCheckbox.value = CheckboxState.uncheked
  else emptySearchCheckbox.value = CheckboxState.checked
}

async function importRisStudies({
  files,
  date,
  sourceId,
  query,
  filters,
}: {
  files: { isValid: boolean; file: File }[]
  date: string
  sourceId: string
  query?: string
  filters?: string
}) {
  if (
    !date ||
    files.some((cf) => !cf.isValid) ||
    (props.importSource.type === ImportSourceType.DATABASE && !query)
  ) {
    throw new Error('Missing or incorrect data')
  }
  loading.start()
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === sourceId,
    )
    if (!dataSource) {
      throw new Error('import source does not exist')
    }
    await review.importRisSearch({
      citationFiles: files.map((cf) => cf.file),
      date,
      query,
      filters,
      importSourceId: sourceId,
    })
    reset()
    emit('hide')
    snackbar.show(SnackbarState.SUCCESS, 'Studies imported successfully')
  } catch (e) {
    snackbar.show(SnackbarState.ERROR, 'Import failed, please try again')
    throw e
  } finally {
    loading.stop()
  }
}
function isSubmitDisabled() {
  return (
    !!citationFilesError.value ||
    !!dateValidationError.value ||
    (props.importSource.type === ImportSourceType.DATABASE && !query.value) ||
    (emptySearchCheckbox.value !== CheckboxState.checked &&
      citationFiles.value.length === 0)
  )
}
function reset() {
  citationFiles.value = []
  date.value = format(new Date(), 'yyyy-MM-dd')
  query.value = ''
  filters.value = ''
  emptySearchCheckbox.value = CheckboxState.uncheked
}

const emit = defineEmits<(e: 'hide') => void>()
</script>
