import { StudyState } from '@app/types'
import { Id } from '../types/id.type'
import { Attribute } from './attributes.model'
import { FileMeta } from './file-meta.model'
import { Search } from './search.model'
import { PeerReviewStatus } from '../types/peerReview.type'
import { StudyDesignStatus } from '../types/studyDesignStatus.type'
import { StudyDesign } from './studyDesign.model'
import { ReviewItemType } from '../types/reviewItemType.type'

export interface WithState {
  get state(): StudyState
}

export abstract class ReviewItem implements WithState {
  id: Id
  type: ReviewItemType
  parentStudyId?: Id
  maybeParentStudyId?: number
  reviewId: Id
  isFavorite?: boolean
  complaintDate?: Date
  titleAndAbstractScreening?: string
  fullTextScreening: string
  search: Search
  pdfFile?: FileMeta
  reference?: string
  appraisal?: { [key: string]: string }
  oxfordLevelOfEvidence?: string
  synthesis: { attributes: Attribute[] }
  peerReviewStatus?: PeerReviewStatus
  studyDesignStatus?: StudyDesignStatus
  studyDesign?: StudyDesign
  metadata?: any

  constructor(data: {
    id?: Id
    isFavorite?: boolean
    complaintDate?: Date
    search: Search
    titleAndAbstractScreening?: string
    fullTextScreening?: string
    reviewId: Id
    parentStudyId?: Id
    maybeParentStudyId?: number
    pdfFile?: FileMeta
    appraisal?: { [key: string]: string }
    oxfordLevelOfEvidence?: string
    synthesis?: { attributes: Attribute[] }
    peerReviewStatus?: PeerReviewStatus
    studyDesignStatus?: StudyDesignStatus
    studyDesign?: StudyDesign
  }) {
    this.id = data.id ?? 0
    this.isFavorite = data.isFavorite ?? false
    this.complaintDate = data.complaintDate
    this.search = data.search
    this.titleAndAbstractScreening = data.titleAndAbstractScreening ?? ''
    this.fullTextScreening = data.fullTextScreening ?? ''
    this.reviewId = data.reviewId ?? 0
    this.parentStudyId = data.parentStudyId
    this.maybeParentStudyId = data.maybeParentStudyId
    this.pdfFile = data.pdfFile
    this.appraisal = data.appraisal ?? {}
    this.oxfordLevelOfEvidence = data.oxfordLevelOfEvidence ?? ''
    this.synthesis = data.synthesis ?? { attributes: [] }
    this.peerReviewStatus = data.peerReviewStatus ?? PeerReviewStatus.default
    this.studyDesignStatus =
      data.studyDesignStatus ?? StudyDesignStatus.applicable
    this.studyDesign = data.studyDesign
    this.type = ReviewItemType.default
  }

  abstract get state(): StudyState

  get isDuplicate(): boolean {
    return !!this.parentStudyId
  }

  get maybeDuplicate(): boolean {
    return !!this.maybeParentStudyId
  }

  markAsDuplicate(parentStudyId: number) {
    this.titleAndAbstractScreening = ''
    this.fullTextScreening = ''
    this.parentStudyId = parentStudyId
    this.maybeParentStudyId = undefined
  }

  markAsNotDuplicate() {
    this.parentStudyId = undefined
    this.maybeParentStudyId = undefined
  }

  setTitleAndAbstractScreening(key: string) {
    this.titleAndAbstractScreening = key
  }

  clearTitleAndAbstractScreening() {
    this.titleAndAbstractScreening = ''
    this.fullTextScreening = ''
  }

  setFullStudyScreening(key: string) {
    if (!this.titleAndAbstractScreening)
      throw new Error('title and abstract screening missing')
    this.fullTextScreening = key
  }

  clearFullStudyScreening() {
    this.fullTextScreening = ''
  }
}
