<template>
  <svg
    id="flag-icons-pe"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path fill="#D91023" d="M0 0h512v512H0z" />
    <path fill="#fff" d="M170.7 0h170.6v512H170.7z" />
  </svg>
</template>
