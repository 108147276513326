import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import axios from 'axios'

export class EuropePmcApiClient {
  private readonly baseUrl = 'https://www.ebi.ac.uk/europepmc/webservices/rest'

  async searchWorks(query: string): Promise<ArticleMetadata[]> {
    const encodedQuery = encodeURIComponent(query)
    const pageSize = 1000
    let allArticles: any[] = []
    let nextPageUrl = `${this.baseUrl}/search?query=${encodedQuery}&format=json&pageSize=${pageSize}&resultType=core`

    try {
      while (nextPageUrl) {
        const response = await axios.get(nextPageUrl)
        allArticles = allArticles.concat(response.data.resultList.result)
        nextPageUrl = response.data.nextPageUrl
      }
    } catch (error: any) {
      console.error(`Failed to fetch articles for query: ${query}`, error.stack)
      throw new Error('Failed to fetch articles from Europe PMC API')
    }

    return allArticles.map((work: any) => this.mapToArticle(work))
  }

  async searchArticles(query: string): Promise<ArticleMetadata[]> {
    return this.searchWorks(query)
  }

  private mapToArticle(article: any): ArticleMetadata {
    return new ArticleMetadata({
      doi: article.doi,
      title: article.title,
      abstract: article.abstractText,
      authors: article.authorList?.author
        ?.map((author: any) => author?.fullName)
        .filter(Boolean),
      url: article.doi,
      publishYear: article.pubYear,
      rawData: JSON.stringify(article),
      volumeNumber: article.journalInfo?.volume,
      issueNumber: article.journalInfo?.issue,
      journal: article.journalInfo?.journal?.medlineAbbreviation,
      pagesNumber: article.pageInfo,
      //reference: work.referenceList?.reference.map(ref => ref.id).join(', '),
      pmid: article.pmid,
      potentialPdfUrl: article.fullTextUrlList?.fullTextUrl.find(
        (url: any) => url.documentStyle === 'pdf',
      )?.url,
      pmcId: article.pmcid,
    })
  }
}
