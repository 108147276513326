<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    class="fill-current"
  >
    <path
      d="M12.0833 10.0003L7.91659 5.83366L7.91659 14.167L12.0833 10.0003Z"
    />
  </svg>
</template>
