<template>
  <tr class="border-t border-black/20 transition duration-300">
    <td class="align-top">
      <div class="p-2 text-slate-700 text-sm">{{ searchNumber }}</div>
    </td>

    <td class="align-top">
      <div class="p-2 text-slate-700 font-[400] text-sm">
        {{
          search.searchDate
            ? format(new Date(search.searchDate), settings.dateFormat.value)
            : '-'
        }}
      </div>
    </td>
    <td
      v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
      class="align-top"
    >
      <div class="p-1 text-slate-700">
        <span
          v-tooltip="
            'Title: ' +
            (getStudyById(search.parentStudyId!) as Article)?.metadata?.title
          "
          class="flex items-center gap-1 w-fit"
        >
          {{ search.parentStudyId ?? '-' }}
          <InformationCircleIcon class="text-black-20 w-4 h-4" />
        </span>
      </div>
    </td>
    <td
      v-if="
        importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
        importSource.id !== BuiltInImportSourceId.CITATION_SEARCH &&
        importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
      "
      class="align-top text-center p-2"
    >
      <button @click="toggleRowExpansion">
        <ChevronDownFilledIcon v-if="isRowExpanded" />
        <ChevronRightFilledIcon v-else />
      </button>
    </td>
    <td
      v-if="
        importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
        importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
      "
      class="align-top max-w-[400px]"
    >
      <ExpansionPanel
        v-model="isRowExpanded"
        class="p-2 text-slate-700 text-sm"
      >
        <template #activator>
          <div class="space-y-1">
            <p :class="{ 'line-clamp-1': !isRowExpanded }">
              <span class="font-medium">Search: </span>
              <span class="font-[400]">{{
                search.searchQuery ? search.searchQuery : '-'
              }}</span>
            </p>
            <p :class="{ 'line-clamp-1': !isRowExpanded }">
              <span class="font-medium">Filters: </span>
              {{
                search.filters
                  ? importSource.id === BuiltInImportSourceId.PMC
                    ? search.filters
                    : formatDatesInSearchFilters(search.filters)
                  : '-'
              }}
            </p>
          </div>
        </template>
        <template #default>
          <div class="space-y-2 mt-2">
            <div
              v-if="
                importSource.id === BuiltInImportSourceId.PUBMED ||
                importSource.id === BuiltInImportSourceId.PMC
              "
              class="align-top"
            >
              <div class="text-slate-700">
                <span class="font-semibold">Search details:</span>
                {{
                  search.queryTranslation
                    ? updateEndDateInPubmedQuery(search.queryTranslation)
                    : '-'
                }}
              </div>
            </div>
          </div>
        </template>
      </ExpansionPanel>
    </td>

    <td class="align-top">
      <div
        v-if="importSource.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES"
        class="p-2 space-y-1 max-w-[500px] text-slate-700 font-medium"
      >
        <div
          v-for="db in fsnDatabases(search)"
          :key="db.name"
          class="flex items-center gap-2"
        >
          <component :is="db.icon" class="rounded-full w-4 h-4" />
          <div class="text-xs">{{ db.name }}</div>
          <div class="text-xs">{{ db.resultsCount }}</div>
        </div>
      </div>
    </td>
    <td class="align-top text-center">
      <div class="p-2 text-slate-700 text-sm">
        <a
          v-if="getSearchUrl(search)"
          :href="getSearchUrl(search)"
          target="_blank"
          rel="noopener"
          class="underline text-primary"
        >
          {{ search.studies?.length }}
        </a>
        <div v-else>{{ search.studies?.length }}</div>
      </div>
    </td>
    <td
      v-if="
        review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
        !review.entity.value.isArchived &&
        !review.entity.value.isLocked
      "
      class="align-top"
    >
      <div class="pt-2 flex justify-end">
        <FloatingMenu>
          <template #activator="{ show }">
            <button
              class="flex px-2 justify-center items-center rounded-full text-red-600"
              @click="show"
            >
              <TrashIcon class="w-4 h-4" />
            </button>
          </template>
          <template #content="{ hide }">
            <div class="p-4 space-y-2">
              <div>
                <p v-if="doesSearchContainScreenedStudies(search)">
                  This search contains screened studies, are you sure you want
                  to delete it?
                </p>
                <p v-else>Are you sure you want to delete this search?</p>
              </div>
              <div>
                <button
                  class="bg-neutral-500 hover:bg-neutral-600 text-white py-1 px-2 mr-1"
                  @click.stop="hide"
                >
                  Cancel
                </button>
                <button
                  class="bg-red-500 hover:bg-red-600 text-white py-1 px-2"
                  @click.stop="
                    () => {
                      removeSearch(search).then(() => hide())
                    }
                  "
                >
                  Delete
                </button>
              </div>
            </div>
          </template>
        </FloatingMenu>
      </div>
    </td>
  </tr>
</template>
<script setup lang="ts">
import { Search } from '@core/domain/models/search.model'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { updateEndDateInPubmedQuery } from '@app/utils/updateEndDateInPubmedQuery'
import { Article } from '@core/domain/models/article.model'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { format, parse, isValid } from 'date-fns'
import GermanyFlag from '@app/components/Icons/flags/Germany.vue'
import FranceFlag from '@app/components/Icons/flags/France.vue'
import UKFlag from '@app/components/Icons/flags/UK.vue'
import AustraliaFlag from '@app/components/Icons/flags/Australia.vue'
import USFlag from '@app/components/Icons/flags/USA.vue'
import CyprusFlag from '@app/components/Icons/flags/Cyprus.vue'
import { useSettings } from '@app/composables/use-settings'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { ReviewKey } from '@app/views/Review/use-review'
import { injectStrict } from '@app/utils/injectStrict'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { ImportSource } from '@core/domain/models/import-source.model'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import { ref } from 'vue'
import ChevronDownFilledIcon from '@app/components/Icons/ChevronDownFilledIcon.vue'
import ChevronRightFilledIcon from '@app/components/Icons/ChevronRightFilledIcon.vue'

defineProps<{
  search: Search
  importSource: ImportSource
  searchNumber: number
}>()

const loading = useLoading()
const snackbar = useSnackbar()

function toggleRowExpansion() {
  isRowExpanded.value = !isRowExpanded.value
}

const review = injectStrict(ReviewKey)

const isRowExpanded = ref(false)

const fsnSources = [
  {
    id: 'ansm',
    name: 'ANSM',
    icon: FranceFlag,
  },
  {
    id: 'bfarm',
    name: 'BfArM',
    icon: GermanyFlag,
  },
  {
    id: 'fda',
    name: 'FDA',
    icon: USFlag,
  },
  {
    id: 'mhra',
    name: 'MHRA',
    icon: UKFlag,
  },
  {
    id: 'moh',
    name: 'ΙΥ&ΥΔΥ',
    icon: CyprusFlag,
  },
  {
    id: 'sara',
    name: 'SARA',
    icon: AustraliaFlag,
  },
]

const settings = useSettings()

function getSearchUrl(search: Search) {
  return search.getSearchUrl()
}
const fsnDatabases = (search: Search) => {
  const databases = Array.from(
    new Set(search.studies.map((s) => s.metadata.source).flat()),
  )

  const resultsBySource = databases.reduce((acc, source) => {
    acc[source] =
      search.studies.filter((result) => result?.metadata.source === source) ??
      []
    return acc
  }, {})

  return databases.map((source) => {
    return {
      name: fsnSources.find((s) => s.id === source)?.name,
      icon: fsnSources.find((s) => s.id === source)?.icon,
      resultsCount: resultsBySource[source].length,
    }
  })
}

function formatDatesInSearchFilters(searchFilters: string): string {
  const dateMatches = searchFilters.match(/\d{1,2}([/.-])\d{1,2}\1\d{2,4}/g)

  if (dateMatches) {
    const formattedDates = dateMatches.map((dateStr) => {
      const parsedDate = parse(dateStr, 'dd/MM/yyyy', new Date())

      if (isValid(parsedDate)) {
        return {
          original: dateStr,
          formatted: format(parsedDate, settings.dateFormat.value),
        }
      } else {
        return {
          original: dateStr,
          formatted: dateStr,
        }
      }
    })

    return formattedDates.reduce(
      (newText, { original, formatted }) =>
        newText.replace(original, formatted),
      searchFilters,
    )
  }

  return searchFilters
}

async function removeSearch(search: Search) {
  if (!search) throw new Error('no search selected')
  try {
    loading.start()
    await review.removeSearch(search.id!)
    await review.refresh()
    snackbar.show(SnackbarState.SUCCESS, 'Search removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function doesSearchContainScreenedStudies(search: Search) {
  return search.studies?.some(
    (s) => s.titleAndAbstractScreening ?? s.fullTextScreening,
  )
}

function getStudyById(id: number) {
  const study = review.entity.value.studies?.find((s) => s.id === id)
  return study
}
</script>
