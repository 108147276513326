<template>
  <div
    ref="itemWrapper"
    class="flex -ml-1"
    @dragstart="(event) => onDragStart(event)"
    @dragend="onDragEnd"
  >
    <div class="content-center cursor-move z-11" draggable="true">
      <DragIcon class="w-6 h-4 text-slate-400" />
    </div>
    <slot />
  </div>
</template>
<script setup lang="ts" generic="T extends { order: string | null }">
import DragIcon from '@app/components/Icons/DragIcon.vue'
import { useTemplateRef } from 'vue'

const emit = defineEmits<{
  (e: 'dragstart', d: DragEvent): void
  (e: 'dragend', d: DragEvent): void
}>()
const itemWrapper = useTemplateRef('itemWrapper')
function onDragStart(event: DragEvent) {
  event.dataTransfer!.setDragImage(
    itemWrapper.value!,
    10,
    itemWrapper.value!.offsetHeight / 2,
  )
  emit('dragstart', event)
}

function onDragEnd(event: DragEvent) {
  emit('dragend', event)
}
</script>
