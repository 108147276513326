export class FieldSafetyNoticeMetadata {
  fsnId?: string
  title?: string
  date?: string
  link?: string
  summary?: string
  source?: string
  fulltext?: string
  fsnType?: string
  potentialPdfUrl?: string

  constructor(data: FieldSafetyNoticeMetadata) {
    this.fsnId = data.fsnId
    this.title = data.title
    this.date = data.date
    this.link = data.link
    this.summary = data.summary
    this.source = data.source
    this.fulltext = data.fulltext
    this.fsnType = data.fsnType
    this.potentialPdfUrl = data.potentialPdfUrl
  }
}
