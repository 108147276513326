import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import axios from 'axios'

export class OpenAlexApiClient {
  private readonly baseUrl = 'https://api.openalex.org'

  async searchWorks(query: string): Promise<ArticleMetadata[]> {
    const encodedQuery = encodeURIComponent(query)
    let page = 1
    const perPage = 50
    let allWorks: any[] = []
    let hasMore = true

    try {
      while (hasMore) {
        const response = await axios.get(
          `${this.baseUrl}/works?search=${encodedQuery}&page=${page}&per-page=${perPage}`,
        )
        allWorks = allWorks.concat(response.data.results)
        hasMore = response.data.meta.count > allWorks.length
        page++
      }
    } catch (error) {
      console.error('Error fetching works from OpenAlex API:', error)
      throw new Error('Failed to fetch works from OpenAlex API')
    }

    return allWorks.map((work: any) => this.mapToArticle(work))
  }

  async searchArticles(query: string): Promise<ArticleMetadata[]> {
    return this.searchWorks(query)
  }

  private mapToArticle(work: any): ArticleMetadata {
    return new ArticleMetadata({
      doi: this.getDoiFromUrl(work.doi),
      title: work.title,
      abstract: this.buildAbstractFromInvertedIndex(
        work.abstract_inverted_index,
      ),
      authors: work.authorships.map((au: any) => au.raw_author_name),
      url: work.doi,
      publishYear: work.publication_year.toString(),
      rawData: JSON.stringify(work),
      volumeNumber: work.biblio?.volume,
      issueNumber: work.biblio?.issue,
      //pagesNumber: work.biblio?.first_page + '-' + work.biblio?.last_page,
      //reference: work.referenced_works?.join(', '),
      pmid: work.id.pmid,
      potentialPdfUrl: work.primary_location.pdf_url,
      pmcId: work.id.pmcid,
    })
  }

  private getDoiFromUrl(url: string): string {
    const doiRegex = /(?:https?:\/\/)?(?:dx\.)?doi\.org\/([^\s]+)/
    const match = doiRegex.exec(url)
    return match ? match[1] : ''
  }

  private buildAbstractFromInvertedIndex(invertedIndex: {
    [word: string]: number[]
  }) {
    if (!invertedIndex) return ''

    let maxPos = 0
    for (const positions of Object.values(invertedIndex)) {
      maxPos = Math.max(maxPos, ...positions)
    }

    const textArray = Array(maxPos + 1).fill(null)

    for (const [word, positions] of Object.entries(invertedIndex)) {
      for (const pos of positions) {
        textArray[pos] = word
      }
    }

    const text = textArray.map((word) => word || '').join(' ')

    return text
  }
}
