<template>
  <Tabs
    v-model:tab="activeStepNumber"
    :tabs="steps"
    :style="3"
    @update:step="updateStep"
  >
    <template #tab-content-1>
      <AiAssistantSettings />
    </template>
    <template #tab-content-2>
      <UserSettings />
    </template>

    <template #tab-content-3>
      <ExtensionSettings />
    </template>
    <template #tab-content-4>
      <div class="container px-6 my-8 space-y-4">
        <a
          rel="noopener"
          :href="`${billingPortalUrl}?prefilled_email=${user?.email}`"
          target="_blank"
          class="px-3.5 py-2.5 bg-primary text-white tracking-tighter rounded-md hover:brightness-110 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:hover:brightness-100"
        >
          Go to billing portal
        </a>
      </div>
    </template>
    <template #tab-content-5>
      <div class="container px-6 my-6 space-y-4 text-sm">
        {{ version }}
      </div>
    </template>
  </Tabs>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import AiAssistantSettings from './AiAssistantSettings.vue'
import ExtensionSettings from './ExtensionSettings.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import UserSettings from './UserSettings/UserSettings.vue'
const version = import.meta.env.VITE_EVIDENCE_VERSION

const { user } = injectStrict(AuthKey)
const isBillingDisabled = inject('isBillingDisabled')
const showBilling = computed(
  () => user.value?.role === Role.ADMIN && !isBillingDisabled,
)
const billingPortalUrl = inject('billingPortalUrl')

const steps = [
  ...(user.value?.role === Role.ADMIN
    ? [
        {
          number: 1,
          title: 'AI Assistant ',
        },
      ]
    : []),
  {
    number: 2,
    title: 'User',
  },
  ...(user.value?.role !== Role.READ_ONLY
    ? [
        {
          number: 3,
          title: 'Extension ',
        },
      ]
    : []),

  ...(showBilling.value && user.value?.role === Role.ADMIN
    ? [
        {
          number: 4,
          title: 'Billing ',
        },
      ]
    : []),
  {
    number: 5,
    title: 'About',
  },
]
const route = useRoute()
const router = useRouter()
const activeStepNumber = ref(steps[0].number)
const activeSubStepNumber = ref(1)
if (route.query.tab) {
  activeStepNumber.value = Number(route.query.tab)
}
if (route.query.subtab) {
  activeSubStepNumber.value = Number(route.query.subtab)
}

function updateStep(step: number) {
  router.push({ query: { tab: step, subtab: 1 } })
}
</script>
