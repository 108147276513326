<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <circle cx="1.68816" cy="1.68816" r="1.68816" />
    <circle cx="8.00066" cy="1.68816" r="1.68816" />
    <circle cx="8.00066" cy="8.00066" r="1.68816" />
    <circle cx="1.68816" cy="8.00066" r="1.68816" />
    <circle cx="8.00066" cy="14.3112" r="1.68816" />
    <circle cx="1.68816" cy="14.3112" r="1.68816" />
  </svg>
</template>
