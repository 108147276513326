<template>
  <!-- this is a temporary solution-->
  <template v-if="source.id === BuiltInImportSourceId.MAUDE">
    <component :is="USAFlag" class="rounded-full w-5 h-5" />
  </template>
  <!-- /this is a temporary solution-->
  <template v-else-if="sourceIconUrl">
    <img
      v-show="isLogoValid"
      :key="sourceIconUrl"
      class="w-8 h-8"
      :src="sourceIconUrl"
      alt="source logo"
      @load="
        ({ target: imageElement }) =>
          checkImage(imageElement as HTMLImageElement)
      "
      @error="
        ({ target: imageElement }) =>
          handleImageError(imageElement as HTMLImageElement)
      "
    />
    <div
      v-show="!isLogoValid"
      class="w-8 h-8 p-1 border-primary/50 border-2 text-primary/80 rounded-full"
    >
      <DatabaseIcon class="w-full h-full" />
    </div>
  </template>

  <div
    v-else
    class="w-8 h-8 p-1 border-primary/50 border-2 text-primary/80 rounded-full"
  >
    <DatabaseIcon class="w-full h-full" />
  </div>
</template>
<script setup lang="ts">
import DatabaseIcon from '@app/components/Icons/DatabaseIcon.vue'
import { USAFlag } from '@app/components/Icons/flags'
import { isUrlValid } from '@app/utils/urlValidation'
import { ImportSource } from '@core/domain/models/import-source.model'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { watchDebounced } from '@vueuse/core'
import { ref } from 'vue'
const props = defineProps<{
  source: ImportSource
  iconUrl?: string
}>()
const isLogoValid = ref(true)
function checkImage(target: HTMLImageElement) {
  const img = target

  if (img.naturalWidth === 16 && img.naturalHeight === 16) {
    isLogoValid.value = false
  } else {
    isLogoValid.value = true
  }
}

function handleImageError(target: HTMLImageElement) {
  target.src = '/sources-icons/custom-source.png'
}

function buildSourceIconUrl(sourceUrl?: string): string {
  if (props.iconUrl) return props.iconUrl

  if (props.source.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES) {
    return '/sources-icons/safety-information.svg'
  }

  if (props.source.id === BuiltInImportSourceId.CITATION_SEARCH) {
    return '/sources-icons/hand-search-icon.svg'
  }

  if (props.source.id === BuiltInImportSourceId.HAND_SEARCH) {
    return '/sources-icons/hand-search-icon.svg'
  }

  if (!sourceUrl || !isUrlValid(sourceUrl))
    return '/sources-icons/custom-source.png'

  return `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
    sourceUrl,
  )}&sz=64`
}

const sourceIconUrl = ref(buildSourceIconUrl(props.source.url ?? ''))

watchDebounced(
  () => props.source.url,
  (url) => {
    sourceIconUrl.value = buildSourceIconUrl(url)
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
)
</script>
