<template>
  <div
    class="relative"
    :class="{
      'bg-slate-300 h-0': isDragging && !isDraggedOver,
    }"
    @dragover.prevent
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @drop="isDraggedOver = false"
  >
    <div
      :class="{
        'bg-blue-500 h-0.5 w-[calc(100%-45px)] mx-auto': isDraggedOver,
      }"
    ></div>
    <div v-if="isDragging" class="absolute h-10 -top-5 w-full z-10" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  isDragging: boolean
}>()
const isDraggedOver = ref(false)
function onDragEnter() {
  isDraggedOver.value = true
}

function onDragLeave() {
  isDraggedOver.value = false
}
</script>
