import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import { PubmedApiClient } from '@infrastructure/pubmedApi/pubmedApi.client'
import axios from 'axios'
import { parseStringPromise } from 'xml2js'

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export class PmcApiClient {
  constructor(private readonly pubmedApiClient: PubmedApiClient) {}

  private async fetchPmcArticles(idList: string[]): Promise<ArticleMetadata[]> {
    const batchSize = 50
    const batches = this.chunkArray(idList, batchSize)

    const allArticles: ArticleMetadata[] = []
    const notFoundIds: string[] = []

    for (const batch of batches) {
      await delay(1000)
      const postData = this.buildPostData(batch)

      try {
        const fetchDetails = await this.fetchArticleDetails(postData)
        if (fetchDetails.status !== 200) {
          console.error(
            'HTTP error! status:',
            batch,
            fetchDetails.status,
            fetchDetails,
          )
        }

        const detailsXml = fetchDetails.data
        const parsedXml = await parseStringPromise(detailsXml)
        const pmcArticleSet = parsedXml['pmc-articleset']

        if (pmcArticleSet.Reply && pmcArticleSet.Reply.length > 0) {
          notFoundIds.push(
            ...pmcArticleSet.Reply.map((reply: any) => `PMC${reply.$.Id}`),
          )
        }

        const batchArticles = await this.parsePmcArticles(pmcArticleSet)
        allArticles.push(...batchArticles)
      } catch (error) {
        console.error('Error fetching article details:', error)
      }
    }

    const allConvertedIds = await this.convertPmcIds(notFoundIds)
    const allPubmedArticles = await this.fetchPubmedArticles(allConvertedIds)

    allArticles.push(...allPubmedArticles)

    return allArticles
  }

  async searchArticles(
    query: string,
  ): Promise<{ articles: ArticleMetadata[]; queryTranslation: string }> {
    try {
      const response = await axios.get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pmc&term=${encodeURIComponent(query)}&retmode=json&retstart=0&retmax=9999`,
      )
      const articles = await this.fetchPmcArticles(
        response.data.esearchresult.idlist,
      )
      return {
        articles,
        queryTranslation: response.data.esearchresult.querytranslation,
      }
    } catch (error) {
      console.error('Error searching articles:', error)
      throw error
    }
  }

  private buildPostData(batch: string[]): URLSearchParams {
    const postData = new URLSearchParams()
    postData.append('db', 'pmc')
    postData.append('id', batch.join(','))
    postData.append('retmode', 'xml')
    return postData
  }

  private async fetchArticleDetails(postData: URLSearchParams): Promise<any> {
    return axios.post(
      'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi',
      postData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
  }

  private async convertPmcIds(idList: string[]): Promise<string[]> {
    const batchSize = 50
    const batches = this.chunkArray(idList, batchSize)

    const convertedPromises = batches.map(async (batch) => {
      await delay(1000)

      const response = await axios.get(
        `https://www.ncbi.nlm.nih.gov/pmc/utils/idconv/v1.0/?ids=${batch.join(',')}`,
      )
      const xml = response.data
      const parsed = await parseStringPromise(xml)
      return parsed.pmcids.record.map((r: any) => r.$.pmid)
    })

    const convertedIds = await Promise.all(convertedPromises)
    return convertedIds.flat()
  }

  private async fetchPubmedArticles(
    idList: string[],
  ): Promise<ArticleMetadata[]> {
    const batchSize = 50
    const batches = this.chunkArray(idList, batchSize)

    const fetchPubmedPromises = batches.map(async (batch) => {
      await delay(1000)
      return this.pubmedApiClient.fetchStudiesDetails(batch)
    })
    const pubmedArticles = await Promise.all(fetchPubmedPromises)

    return pubmedArticles.flat().map((article) => {
      return new ArticleMetadata({
        title: article.title,
        abstract: article.abstract,
        authors: article.authors,
        doi: article.doi,
        url: article.url,
        publishYear: article.publishYear,
        journal: article.journal,
        volumeNumber: article.volumeNumber,
        issueNumber: article.issueNumber,
        pagesNumber: article.pagesNumber,
        pmid: article.pmid,
      })
    })
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, (index + 1) * size),
    )
  }

  private async parsePmcArticles(
    pmcArticleSet: any,
  ): Promise<ArticleMetadata[]> {
    return pmcArticleSet.article.map((article: any) => {
      try {
        const front = article.front[0]
        const articleMeta = front['article-meta'][0]
        const journalMeta = front['journal-meta'][0]

        return new ArticleMetadata({
          title: this.buildTitle(articleMeta),
          abstract: this.buildAbstract(articleMeta),
          authors: this.buildAuthors(articleMeta),
          doi: this.buildDoi(articleMeta),
          url: this.buildUrl(this.buildPmcid(articleMeta)),
          publishYear: this.buildPublishYear(articleMeta),
          journal: this.buildJournal(journalMeta),
          volumeNumber: this.buildVolumeNumber(articleMeta),
          issueNumber: this.buildIssueNumber(articleMeta),
          pagesNumber: this.buildPagesNumber(articleMeta),
          pmid: this.buildPmid(articleMeta),
          potentialPdfUrl:
            this.buildPotentialPdfUrl(articleMeta) !== ''
              ? this.buildUrl(this.buildPmcid(articleMeta)) +
                'pdf/' +
                this.buildPotentialPdfUrl(articleMeta)
              : '',
          pmcId: this.buildPmcid(articleMeta),
        })
      } catch (error) {
        console.error('Error parsing article: ', error)
      }
    })
  }

  private buildPotentialPdfUrl(articleMeta: any): string {
    if (articleMeta['self-uri']) {
      const pdfUri = articleMeta['self-uri'].find(
        (uri: any) => uri.$?.['xlink:title'] === 'pdf',
      )
      if (pdfUri) return pdfUri.$['xlink:href']
    }

    if (articleMeta['article-id']) {
      const manuscriptId = articleMeta['article-id'].find(
        (id: any) => id.$?.['pub-id-type'] === 'manuscript',
      )
      if (manuscriptId) return `${manuscriptId._}.pdf`
    }

    if (articleMeta['elocation-id']) {
      return `${articleMeta['elocation-id'][0]}.pdf`
    }

    if (articleMeta['volume'] && articleMeta['fpage']) {
      return `e-${articleMeta['volume'][0]}-${articleMeta['fpage'][0]}.pdf`
    }

    return ''
  }

  private buildTitle(articleMeta: any) {
    return (
      (articleMeta?.['title-group']?.[0]?.['article-title']?.[0]?._ ??
        articleMeta?.['title-group']?.[0]?.['article-title']?.[0]) ||
      null
    )
  }

  private buildAbstract(articleMeta: any) {
    if (!Array.isArray(articleMeta.abstract)) return undefined

    return articleMeta.abstract
      .map((section: any) => this.processSection(section))
      .join(' ')
      .trim()
  }

  private processSection(section: any): string {
    if (Array.isArray(section.sec)) {
      return this.processSectionedAbstract(section)
    } else if (Array.isArray(section.p)) {
      return this.processParagraphs(section.p)
    }
    return ''
  }

  private processSectionedAbstract(section: any): string {
    return section.sec
      .map((subsection: any) => {
        const title = this.getTitle(subsection)
        const paragraphText = this.processParagraphs(subsection.p)
        return `${title}: ${paragraphText}`
      })
      .join(' ')
  }

  private getTitle(subsection: any): string {
    return Array.isArray(subsection.title) ? subsection.title[0] : ''
  }

  private processParagraphs(paragraphs: any[]): string {
    return paragraphs
      .map((paragraph: any) => this.getParagraphText(paragraph))
      .join(' ')
  }

  private getParagraphText(paragraph: any): string {
    return paragraph._ || (typeof paragraph === 'string' ? paragraph : '')
  }

  private buildAuthors(articleMeta: any) {
    return (
      articleMeta['contrib-group']?.[0]?.contrib
        .filter((contrib: any) => contrib.$['contrib-type'] === 'author')
        .map(
          (author: any) =>
            `${author.name?.[0]?.surname?.[0]} ${author.name?.[0]?.['given-names']?.[0]}`,
        ) ?? []
    )
  }

  private buildPmid(articleMeta: any) {
    return articleMeta['article-id']?.find(
      (id: any) => id?.$?.['pub-id-type'] === 'pmid',
    )?._
  }

  private buildPmcid(articleMeta: any) {
    return articleMeta['article-id']?.find(
      (id: any) => id?.$?.['pub-id-type'] === 'pmc',
    )?._
  }

  private buildDoi(articleMeta: any) {
    return articleMeta['article-id']?.find(
      (id: any) => id?.$?.['pub-id-type'] === 'doi',
    )?._
  }

  private buildPublishYear(articleMeta: any) {
    return articleMeta['pub-date']?.[0]?.year?.[0]
  }

  private buildJournal(journalMeta: any) {
    return journalMeta['journal-title-group']?.[0]?.['journal-title']?.[0]
  }

  private buildVolumeNumber(articleMeta: any) {
    return articleMeta.volume?.[0]
  }

  private buildIssueNumber(articleMeta: any) {
    return articleMeta.issue?.[0]
  }

  private buildUrl(pmcid: any) {
    return pmcid
      ? `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC${pmcid}/`
      : undefined
  }

  private buildPagesNumber(articleMeta: any) {
    return articleMeta.fpage && articleMeta.lpage
      ? `${articleMeta.fpage[0]}-${articleMeta.lpage[0]}`
      : undefined
  }
}
