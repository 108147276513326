<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    class="stroke-current"
  >
    <path
      d="M3.33325 13.3366L3.33325 14.1699C3.33325 15.5506 4.45254 16.6699 5.83325 16.6699L14.1666 16.6699C15.5473 16.6699 16.6666 15.5506 16.6666 14.1699L16.6666 13.3366M13.3333 10.0033L9.99992 13.3366M9.99992 13.3366L6.66658 10.0033M9.99992 13.3366L9.99992 3.33659"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
