import { Team } from '@core/domain/models/team.model'
import { Api } from '@infrastructure/datasource/review-manager-api'

export class TeamsService {
  constructor(private _reviewManagerService: Api<unknown>) {}

  async createTeam(data: { name: string }) {
    if (!data.name) throw Error('name is required')
    return this._reviewManagerService.api.teamsControllerCreateTeam(data)
  }

  async findTeams(): Promise<Team[]> {
    const { data } =
      await this._reviewManagerService.api.teamsControllerFindTeams()
    return data.map(
      (team: { id: string; name: string }) => new Team(team.id, team.name),
    )
  }
}
