<template>
  <div>
    <template v-for="(item, index) in sortedItems" :key="'group-' + index">
      <div class="group">
        <PlacementAnchor
          :is-dragging="isDragging"
          @drop.prevent="
            () =>
              drop(sortedItems[index - 1]?.order ?? null, item.order ?? null)
          "
        />
        <SortableItem @dragstart="onDragStart(item)" @dragend="onDragEnd">
          <slot
            :key="'item-slot-' + index"
            :item="item"
            :index="index"
            name="item"
          />
        </SortableItem>
        <PlacementAnchor
          :is-dragging="isDragging"
          @drop.prevent="
            () =>
              drop(item.order ?? null, sortedItems[index + 1]?.order ?? null)
          "
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts" generic="T extends { order: string | null }">
import { computed, ref } from 'vue'
import { generateKeyBetween } from 'fractional-indexing'
import PlacementAnchor from './PlacementAnchor.vue'
import SortableItem from './SortableItem.vue'

const props = defineProps<{
  items: T[]
}>()

let draggedItem = ref<T | null>()
let isDragging = ref(false)
const emit = defineEmits<
  (
    e: 'item:moved',
    d: {
      item: T
      fractionalIndex: string
    },
  ) => void
>()

const sortedItems = computed(() =>
  props.items.toSorted((a, b) => {
    if (a.order === null && b.order === null) return 0
    if (a.order === null) return -1
    if (b.order === null) return 1
    return a.order > b.order ? 1 : -1
  }),
)

function onDragStart(item: T) {
  draggedItem.value = item
  setTimeout(() => {
    isDragging.value = true
  }, 0)
}

function onDragEnd() {
  isDragging.value = false
  draggedItem.value = null
}

function drop(before: string | null, after: string | null) {
  if (!draggedItem.value) return
  if (before === draggedItem.value.order || after === draggedItem.value.order)
    return
  const fractionalIndex = generateKeyBetween(before, after)
  emit('item:moved', { item: draggedItem.value, fractionalIndex })
}
</script>
