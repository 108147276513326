<template>
  <button
    class="flex flex-col px-4 py-6 group rounded-2xl text-slate-300 text-left border-slate-300 border w-full transition gap-1"
    :class="[
      {
        'bg-blue-50 !border-blue-500': isActive,
        ' cursor-not-allowed ': isDisabled,
        'hover:bg-blue-50 hover:border-blue-500': !isDisabled,
      },
    ]"
    :disabled="isDisabled"
    @click="emit('handle')"
  >
    <div class="flex items-start w-full">
      <div class="space-y-1 flex-1">
        <p
          class="font-semibold uppercase text-blue-500 text-sm tracking-tighter"
        >
          {{ title }}
        </p>
        <p
          class="text-slate-900 font-semibold transition leading-6 py-1 tracking-tighter text-lg"
          :class="{
            '!text-primary ': isActive,
            'group-hover:text-primary ': !isDisabled,
          }"
        >
          {{ subtitle }}
        </p>
      </div>
      <CheckCircleIcon v-if="isActive" class="text-green-600 w-8" />
    </div>
    <p class="text-slate-500 font-400 text-sm leading-5">
      {{ description }}
    </p>
  </button>
</template>
<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/solid'

withDefaults(
  defineProps<{
    title: string
    subtitle?: string
    description?: string
    isActive?: boolean
    isDisabled?: boolean
  }>(),
  {
    isActive: false,
    subtitle: '',
    description: '',
    isDisabled: false,
  },
)
const emit = defineEmits<(e: 'handle') => void>()
</script>
