<template>
  <div>
    <div class="mb-4 text-blue-700 font-semibold tracking-tighter text-lg">
      Custom review
    </div>
    <div v-if="!isBillingDisabled" class="rounded-md bg-blue-50 p-4 mb-6">
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon
            class="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            You are adding a new review to your plan. After the trial period
            ends, you will be charged 19.90€ per billing cycle.
          </p>
        </div>
      </div>
    </div>
    <div class="w-2/4">
      <TextInput
        ref="reviewNameInput"
        v-model="reviewName"
        label="Review name"
        type="text"
        placeholder="Review name"
        :rules="[(v: string) => !!v || 'Please enter a review name']"
        @enter="emit('createReview')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'
import { inject } from 'vue'
import { CreateReviewKey } from '../useCreateProjectReview'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'

const { reviewName } = injectStrict(CreateReviewKey)
const isBillingDisabled = inject('isBillingDisabled')
const emit = defineEmits<(e: 'createReview') => void>()
</script>
