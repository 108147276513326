<template>
  <div ref="wrapperElement" @click="!readonly ? open() : ''">
    <slot :id="id" name="label">
      <label
        v-if="label"
        class="block text-sm font-medium leading-5 text-slate-700"
        :for="id"
      >
        {{ label }}
      </label>
    </slot>

    <div class="relative">
      <input
        ref="textInput"
        :disabled="readonly"
        type="text"
        name=""
        :value="searchQuery"
        class="w-full focus:outline-none dark:text-white disabled:bg-transparent font-normal text-sm truncate px-3.5 pl-2.5 py-2.5 pr-[90px] flex items-center justify-between rounded-md bg-white border"
        :class="[
          inputClasses,
          {
            ' bg-white hover:border-blue-500 focus:border-blue-500 border-slate-300 ring-blue-500':
              !readonly,
            'bg-black/5': readonly,
          },
        ]"
        :placeholder="placeholder"
        @input="onSearchQueryChange(($event.target as HTMLInputElement).value)"
        @keydown.down.prevent="highlightNextItem"
        @keydown.up.prevent="highlightPreviousItem"
        @keydown.enter.prevent="handleEnter"
      />
      <ChevronUpDownIcon
        v-if="!canAddItems"
        class="w-5 h-5 text-primary stroke-2 dark:text-white absolute right-1 top-3"
      />

      <button
        v-else-if="canAddItems"
        class="absolute right-4 top-1.5 inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
        @click="handleEnter"
      >
        <ArrowTurnDownLeftIcon class="w-3.5" /> Enter
      </button>
    </div>

    <div
      v-if="
        filteredItems.length > 0 || (!canAddItems && filteredItems.length === 0)
      "
      ref="content"
      class="mt-1 border border-slate-300 rounded-md"
    >
      <div
        ref="scrollAreaElement"
        class="max-h-[200px] w-full overflow-auto font-normal z-50 text-base"
      >
        <div>
          <template
            v-for="(item, index) in filteredItems"
            :key="item[itemOptions.valueProperty]"
          >
            <button
              :ref="(el) => setItemRef(el as HTMLButtonElement)"
              class="px-2 py-1 truncate hover:bg-primary hover:text-white focus:bg-gray-200 focus:outline-none w-full text-left font-normal last:rounded-b-lg first:rounded-t-lg"
              :class="{
                'bg-primary text-white': selectedItemIndex === index,
              }"
              @click="selectItem(item)"
            >
              <slot name="item" v-bind="item">
                {{ item[itemOptions.displayProperty] }}
              </slot>
            </button>
          </template>
        </div>
        <div
          v-if="filteredItems.length === 0"
          class="px-4 py-2 text-gray-500 font-normal"
        >
          No items found.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import tippy, { Instance, Props } from 'tippy.js'
import { ref, nextTick, onMounted, watchEffect, watch, computed } from 'vue'
import { createDebounce } from '@app/utils/debounce'
import { ItemOptions } from './types'
import { onClickOutside, useScroll } from '@vueuse/core'
import ChevronUpDownIcon from '@app/components/Icons/ChevronUpDownIcon.vue'
import { v4 as uuid } from 'uuid'
import { ArrowTurnDownLeftIcon } from '@heroicons/vue/24/outline'

const id = uuid()
const props = withDefaults(
  defineProps<{
    items: T[]
    itemOptions: ItemOptions<T>
    placeholder?: string
    debounceMs?: number
    label?: string
    canAddItems?: boolean
    readonly?: boolean
    inputClasses?: string
  }>(),
  {
    debounceMs: 0,
    canAddItems: false,
    readonly: false,
    label: '',
    placeholder: '',
    inputClasses: '',
  },
)

const shownItemsCount = ref(10)
const selectedItemIndex = ref(-1)

function highlightNextItem() {
  if (selectedItemIndex.value < filteredItems.value.length - 1) {
    selectedItemIndex.value += 1
    scrollIntoViewIfNeeded(selectedItemIndex.value)
  }
}

function highlightPreviousItem() {
  if (selectedItemIndex.value > 0) {
    selectedItemIndex.value -= 1
    scrollIntoViewIfNeeded(selectedItemIndex.value)
  }
}

const isOpen = ref(false)
const wrapperElement = ref()
onClickOutside(wrapperElement, () => reset())
const value = defineModel<string | number | object | Array<string | number>>()
const textInput = ref<HTMLInputElement>()
const content = ref<Element>()
const searchQuery = ref('')

watchEffect(() => {
  if (value.value !== null) {
    const selectedItem = props.items.find(
      (item) =>
        (item[props.itemOptions.valueProperty] as unknown as
          | string
          | number) === value.value,
    )
    if (selectedItem) {
      searchQuery.value = String(
        selectedItem[props.itemOptions.displayProperty],
      )
    } else {
      searchQuery.value = ''
    }
  }
})

const debounce = createDebounce()

let tippyInstance: Instance<Props> | null = null

onMounted(() => {
  tippyInstance = tippy(wrapperElement.value as Element, {
    content: content.value,
    hideOnClick: false,
    duration: [300, 100],
    allowHTML: true,
    offset: [0, 0],
    trigger: 'manual',
    placement: 'bottom-start',
    interactive: true,
    animation: 'shift-away',
    arrow: false,
    theme: 'input',
    maxWidth: 'none',
    onShow(instance) {
      const inputWidth = wrapperElement.value.getBoundingClientRect().width
      instance.popperInstance?.setOptions({
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              adaptive: false,
              gpuAcceleration: false,
            },
          },
        ],
      })
      instance.popper.style.width = `${inputWidth}px`
    },
    onHide() {
      isOpen.value = false
    },
  })
})

function handleEnter() {
  if (
    selectedItemIndex.value >= 0 &&
    selectedItemIndex.value < filteredItems.value.length
  ) {
    selectItem(filteredItems.value[selectedItemIndex.value])
  } else if (props.canAddItems && searchQuery.value) {
    addNewItem(searchQuery.value)
  }
  searchQuery.value = ''
}

function addNewItem(newItemValue: string) {
  value.value = newItemValue
  nextTick(() => tippyInstance?.hide())
}

function onSearchQueryChange(v: string) {
  if (v.length > 0) tippyInstance?.show()
  else if (v.length === 0 && props.canAddItems) tippyInstance?.hide()
  debounce(() => {
    searchQuery.value = v
    selectedItemIndex.value = -1
  }, props.debounceMs)
}

function selectItem(item: T) {
  value.value = item[props.itemOptions.valueProperty] as unknown as
    | string
    | number

  nextTick(() => {
    tippyInstance?.hide()
    textInput.value?.blur()
    selectedItemIndex.value = -1
  })
}

function open() {
  if (window.getSelection()?.toString()) return
  if (!props.canAddItems) tippyInstance?.show()
  isOpen.value = true
  textInput.value?.select()
  searchQuery.value = ''
}

function reset() {
  const resetValue = props.items.find(
    (item) =>
      (item[props.itemOptions.valueProperty] as unknown as string | number) ===
      value.value,
  )

  tippyInstance?.hide()
  setTimeout(() => {
    searchQuery.value = resetValue
      ? String(resetValue[props.itemOptions.displayProperty])
      : ''
  }, 100)
}

const scrollAreaElement = ref<HTMLElement | null>(null)
const { y } = useScroll(scrollAreaElement)
watch(y, () => {
  if (y.value >= 40 * shownItemsCount.value - 200)
    shownItemsCount.value = y.value / 40 + 10
})

const itemRefs = ref<HTMLButtonElement[]>([])

const setItemRef = (el: HTMLButtonElement) => {
  if (el) {
    itemRefs.value.push(el)
  }
}
const filteredItems = computed(() => {
  const query = searchQuery.value.toLowerCase()
  const { filterProperties } = props.itemOptions

  return props.items.filter((item) =>
    filterProperties.some((prop) =>
      String(item[prop]).toLowerCase().includes(query),
    ),
  )
})

watch(
  () => [isOpen, filteredItems],
  () => {
    itemRefs.value = []
  },
  { immediate: true },
)

function scrollIntoViewIfNeeded(index: number) {
  nextTick(() => {
    const itemElement = itemRefs.value[index]
    if (itemElement) {
      itemElement.scrollIntoView({ block: 'nearest' })
    }
  })
}
</script>
<style>
.tippy-box[data-theme~='input'] .tippy-content {
  padding: 0;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
}

.tippy-box[data-theme~='input'] > .tippy-backdrop {
  background-color: #fff;
}

.tippy-box[data-theme~='input'] {
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-top: none;
  color: #333;
  box-shadow: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
