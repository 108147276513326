import { Id } from '../types/id.type'
import { ReviewItem } from './reviewItem.model'
import { FileMeta } from './file-meta.model'
import { ImportSource } from './import-source.model'

export class Search {
  id?: Id
  personUndertakingSearch: string
  source: ImportSource
  searchDate?: string
  studies: ReviewItem[]
  citationFiles: FileMeta[]
  searchQuery?: string
  queryFile?: FileMeta
  url?: string
  parentStudyId?: Id
  datefilter?: {
    start: string
    end: string
  }
  filters?: string
  queryTranslation?: string
  constructor(data: Partial<Search> & { source: ImportSource }) {
    this.id = data.id
    this.personUndertakingSearch = data.personUndertakingSearch ?? ''
    this.source = data.source
    this.searchDate = data.searchDate ?? undefined
    this.studies = data.studies ?? []
    this.citationFiles = data.citationFiles as FileMeta[]
    this.searchQuery = data.searchQuery ?? ''
    this.queryFile = data.queryFile as FileMeta
    this.url = data.url ?? ''
    this.parentStudyId = data.parentStudyId
    this.datefilter = data.datefilter
    this.filters = data.filters
    this.queryTranslation = data.queryTranslation
  }

  getSearchUrl(): string {
    if (this.url) return this.url
    else if (this.searchQuery) {
      const query = encodeURIComponent(this.searchQuery)
      switch (this.source.id) {
        case 'pubmed':
          return `https://pubmed.ncbi.nlm.nih.gov/?term=${query}`
        case 'google-scholar':
          return `https://scholar.google.com/scholar?q=${query}`
        case 'pmc':
          return `https://www.ncbi.nlm.nih.gov/pmc/?term=${query}`
        case 'europe-pmc':
          return `https://europepmc.org/search?query=${query}`
        case 'open-alex':
          return `https://openalex.org/works?filter=default.search%3A${query}`
        default:
          return ''
      }
    }
    return ''
  }
}
