import { ProjectsService } from '@core/application/projects.service'
import { ReviewsService } from '@core/application/reviews.service'
import { SettingsService } from '@core/application/settings.service'
import { UsersService } from '@core/application/users.service'
import { InjectionKey } from 'vue'
import type useAuth from './composables/use-auth'
import type useDevToolbox from './composables/use-dev-toolbox'
import { TeamsService } from '@core/application/teams.service'
import { AutoImportService } from '@core/application/autoImportService'

export const ProjectsServiceKey: InjectionKey<ProjectsService> =
  Symbol('ProjectsService')

export const ReviewsServiceKey: InjectionKey<ReviewsService> =
  Symbol('ReviewsService')

export const SettingsServiceKey: InjectionKey<SettingsService> =
  Symbol('SettingsService')

export const UsersServiceKey: InjectionKey<UsersService> =
  Symbol('UsersService')

export const AuthKey: InjectionKey<ReturnType<typeof useAuth>> = Symbol('Auth')

export const DevToolboxKey: InjectionKey<ReturnType<typeof useDevToolbox>> =
  Symbol('DevToolbox')

export const TeamsServiceKey: InjectionKey<TeamsService> =
  Symbol('TeamsServiceKey')

export const AutoImportServiceKey: InjectionKey<AutoImportService> =
  Symbol('AutoImportService')
