<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    class="stroke-current"
  >
    <path
      d="M9.02246 4.99967H5.68913C4.76865 4.99967 4.02246 5.74587 4.02246 6.66634V14.9997C4.02246 15.9201 4.76865 16.6663 5.68913 16.6663H14.0225C14.9429 16.6663 15.6891 15.9201 15.6891 14.9997V11.6663M12.3558 3.33301H17.3558M17.3558 3.33301V8.33301M17.3558 3.33301L9.02246 11.6663"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
