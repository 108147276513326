<template>
  <div class="space-y-1">
    <label
      v-if="label && !labelInside"
      :for="id"
      class="block text-sm font-medium leading-5 text-slate-700"
    >
      {{ label }}
      <span v-if="isRequired" class="text-red-600">*</span>
    </label>
    <div class="relative">
      <div
        class="cursor-text h-full flex gap-2 justify-between items-center w-full"
      >
        <input
          v-if="!multiline"
          :id="id"
          ref="input"
          v-model="value"
          :type="inputType"
          class="w-full focus:outline-none dark:text-white disabled:bg-transparent font-normal text-sm px-3.5 py-2.5 flex items-center justify-between rounded-md bg-white border border-slate-300"
          :class="[
            inputClasses,
            {
              ' bg-white hover:border-blue-500 focus:border-blue-500 border-slate-300 ring-blue-500':
                !disabled && !computedError,
              'bg-black/5': disabled,
              'border-red-600 text-red-600': computedError,
            },
          ]"
          :placeholder="placeholder ?? label"
          :disabled="disabled"
          :tabindex="tabindex"
          @input="validate"
          @keydown.enter="emit('enter', value)"
          @blur="emit('blur', value)"
        />
        <textarea
          v-else
          :id="id"
          ref="input"
          v-model="value"
          class="focus:outline-none dark:text-white disabled:bg-transparent font-[400] px-3.5 py-2.5 flex items-center justify-between rounded-md bg-white border"
          :class="[
            {
              ' bg-white hover:border-blue-500 focus:border-blue-500 border-slate-300':
                !disabled && !computedError,
              'bg-black/5': disabled,
              'border-red-600 text-red-600': computedError,
            },
            !canResize ? 'resize-none' : 'w-full',
            inputClasses,
          ]"
          cols="50"
          rows="4"
          :placeholder="placeholder ?? label"
          :disabled="disabled"
          :tabindex="tabindex"
          @keydown.enter.prevent="handleEnter"
          @input="validate"
          @blur="emit('blur', value)"
        />
      </div>
      <div
        tabindex="-1"
        class="h-full flex text-black absolute right-4 items-center gap-1 top-0"
      >
        <button v-if="clearable && value" tabindex="-1" @click="clear()">
          <XCircleIcon class="w-5 h-5" />
        </button>
        <button
          v-if="type === 'password'"
          tabindex="-1"
          @click="togglePassword()"
        >
          <EyeIcon
            v-if="!isPasswordShown"
            class="w-5 h-5 dark:fill-white/70 text-primary"
          />
          <EyeSlashIcon
            v-else
            class="w-5 h-5 dark:fill-white/70 text-primary"
          />
        </button>

        <label tabindex="-1" :for="id" class="flex items-center">
          <slot name="suffix" :value="value" />
        </label>
        <InformationCircleIcon
          v-if="computedError"
          v-tooltip.error="computedError ? computedError : ''"
          class="w-5 h-5 text-red-600 outline-none"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import EyeIcon from '@app/components/Icons/EyeIcon.vue'
import EyeSlashIcon from '@app/components/Icons/EyeSlashIcon.vue'
import XCircleIcon from '../../Icons/XCircleIcon.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const input = ref<HTMLInputElement | HTMLTextAreaElement>()
const id = uuidv4()

const props = withDefaults(
  defineProps<{
    type?: 'text' | 'password' | 'email'
    label?: string
    labelInside?: boolean
    clearable?: boolean
    placeholder?: string
    rules?: ((v: string) => boolean | string)[]
    disabled?: boolean
    multiline?: boolean
    error?: string
    tabindex?: number
    inputClasses?: string
    isRequired?: boolean
    canResize?: boolean
    canAdd?: boolean
  }>(),
  {
    type: 'text',
    clearable: false,
    rules: () => [],
    label: '',
    labelInside: false,
    placeholder: '',
    disabled: false,
    multiline: false,
    error: '',
    tabindex: 0,
    inputClasses: '',
    isRequired: false,
    canResize: false,
    canAdd: false,
  },
)
const emit = defineEmits<{
  (e: 'enter', value: string): void
  (e: 'blur', value: string): void
}>()

const value = defineModel<string>({
  default: '',
})

const isPasswordShown = ref(false)
const inputType = computed(() =>
  props.type === 'text' || props.type === 'email' || isPasswordShown.value
    ? 'text'
    : 'password',
)

function togglePassword() {
  isPasswordShown.value = !isPasswordShown.value
}

function clear() {
  value.value = ''
}

const internalError = ref<string>()

const computedError = computed(() =>
  props.error ? props.error : internalError.value,
)

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function focus() {
  input.value?.focus({
    preventScroll: true,
  })
}
function select() {
  input.value?.select()
}

function handleEnter(event: KeyboardEvent) {
  if (event.shiftKey) {
    value.value += '\n'
  } else {
    emit('enter', value.value)
  }
}

defineExpose({
  validate,
  focus,
  select,
})
</script>
